import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const AuthLayout = ({children}) => {
    const [ divWidth, setDivWidth ] = useState(null);
    const [ sidebarToggle, setSidebarToggle ] = useState(true);
    const { darkmode } = useSelector((state) => state.adminReducers);
    const location = useLocation()?.pathname?.split("/")[1];
    const darkmodeclassName = darkmode === undefined ? "light" : darkmode;

    return(<section className={`adminmain ${darkmodeclassName}`}>
        <Sidebar 
            setDivWidth={setDivWidth} 
            sidebarToggle={sidebarToggle} 
        />
        <div className={!sidebarToggle ? "panelwrapper active close" : "panelwrapper active"}>
            <Header 
                sidebarWidth={divWidth} 
                sidebarToggle={sidebarToggle} 
                setSidebarToggle={setSidebarToggle}
            />
            <div className={(location === "dashboard" || location === "transaction-history" || location === "sales-revenue-report" || location === "schemes-metrics" || location === "warranty-registration-report") ? "panelContentwrapper_new" : "panelContentwrapper"}>
                {children}
            </div>
        </div>
    </section>)
}

export default AuthLayout;