import React from "react";
import Select from 'react-select';

const CommonReactSelectMenu = (props) => {
    const { label, options, selectValue, setSelectValue, isMendatory, errorState, setErrorState, isAllowClearable, customWidth, customeHeight } = props;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
        control: (provided) => ({
            ...provided,
            minHeight: customeHeight, // Adjust the height
            height: customeHeight,
            border: "0px",
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: customeHeight,
            padding: "0 8px",
        }),
        input: (provided) => ({
            ...provided,
            margin: "0px",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: customeHeight,
        }),
    };

    // SELECT ONCHANGE FUNCTIONS
    const CommonSelectOnChange = (value) => {
        if(value !== null) {
            setSelectValue(value);
            if(isMendatory) {
                setErrorState("");
            };
        } else {
            setSelectValue("");
            if(isMendatory) {
                setErrorState(`Please select ${label}`);
            };
        };
    };

    return(<div className="selectMultiselect" style={{ width: customWidth }}>
        {/* COMMON SELECT MENU LIST */}
        <Select 
            value={selectValue}
            onChange={(e) => CommonSelectOnChange(e)} 
            isClearable={isAllowClearable}
            options={options}
            className="godown-select-container" 
            classNamePrefix="godown-select"
            styles={customStyles}
            placeholder={`Select ${label}`}
            menuPlacement="auto"
            menuPortalTarget={document.body}
        />

        {/* ERROR STATE */}
        {isMendatory && (<React.Fragment>
            {errorState !== "" && (<span className="error_medotory">{errorState}</span>)}
        </React.Fragment>)}
    </div>)
};

export default CommonReactSelectMenu;