/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { WebSocketContext } from "../../App";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonTable from "../Common/CommonTable";

const PurchaseList = (props) => {
    const { PurchaseList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ deviceType, setDeviceType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[1]?.Edit || PermissionsAccess?.CRMTools[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "purchase_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "device_type": deviceType,
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, deviceType ]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'customer_name', label: 'Name', type: "label", sort: "", isCollapsable: false },
        { key: 'email', label: 'Email', type: "label", sort: "", isCollapsable: false },
        { key: 'product_name', label: 'Product Name', type: "label", sort: "", isCollapsable: false },
        { key: 'product_value', label: 'Product Value', type: "label", sort: "", isCollapsable: false },
        { key: 'franchise_name', label: 'Franchise Name', type: "label", sort: "", isCollapsable: false },
        { key: 'frame_available', label: 'Frame Available', type: "status_frame_active", sort: "", isCollapsable: false },
        { key: 'frame_number', label: 'Frame Number', type: "label", sort: "", isCollapsable: false },
        { key: 'invoice_code', label: 'Invoice No.', type: "label_invoice_no_purchase_list", sort: "", isCollapsable: false },
        { key: 'invoice_at', label: 'Invoice Date', type: "label_invoice_date_list", sort: "", isCollapsable: false },
        { key: 'mobile', label: 'Mobile', type: "label", sort: "", isCollapsable: false },
        { key: 'state_id', label: 'State', type: "label_get_from_list", filterFrom: { list: "state", label: "state_name" }, sort: "", isCollapsable: false },
        { key: 'district_id', label: 'City', type: "label_get_from_list", filterFrom: { list: "district", label: "district_name" }, sort: "", isCollapsable: false },
        { key: 'pin_code_id', label: 'Pin Code', type: "label_get_from_list", filterFrom: { list: "pin_code", label: "pin_code" }, sort: "", isCollapsable: false },
        { key: 'created_at', label: 'Created At', type: "date", sort: "", isCollapsable: false },
        { key: 'device', label: 'Device Type', type: "label_capitalized", sort: "", isCollapsable: false },
        { key: 'email_send', label: 'Email Sent', type: "status_yes_no", sort: "", isCollapsable: false }
    ];

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"purchase_list"}
            searchType={"Name, Frame Number, Mobile"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            deviceType={deviceType} 
            setDeviceType={setDeviceType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{ height: parseInt(PurchaseList?.pagination?.total_records) === PurchaseList?.list?.length ? "calc(100vh - 140px)" : PurchaseList?.pagination?.total_records > 10 ? "calc(100vh - 175px)" : "calc(100vh - 140px)" }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={PurchaseList} // ACCOUNT LEDGER REPORT LIST
            Pagination={PurchaseList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools[1]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: (selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools[1]?.Edit),
                label: "edit_file_purchase_download",
                naviagte: `/edit-purchase/`
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isTotalAndGrand={{
                isShow: false,
                filterType: "",
                colSpan: 0
            }}
        />
    </section>)
}

export default PurchaseList;