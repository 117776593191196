/* eslint-disable */
import React, { useEffect, useContext } from "react";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import { PO_APPROVALS_URL } from "../../Shared/constant";
import { updateOrderManagementFilter, ClearFormSetFutios } from "../../../redux/actions/adminActions";
import CommonTable from "../../Common/CommonTable";
import MainOrderDataTableFilter from "../../Common/MainOrderDataTableFilter";

const POApprovals = (props) => {
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, OrderWareHouseApprovalsList, sortingfor, selfInfo, OrderManagmentFilter } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        Dispatch(updateOrderManagementFilter({
            WarehouseApprovals: {
                CurrentPage: page,
            }
        }));
    };

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[3]?.Edit || PermissionsAccess?.OrderManagement[3]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "region_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get order_list all
    useEffect(() => {
        if (websocket) {
            if(selfInfo?.user?.user_type === 'super_admin' || selfInfo?.user?.user_type === "admin" || ((PermissionsAccess?.OrderManagement[3]?.Edit || PermissionsAccess?.OrderManagement[3]?.View) && selfInfo?.user?.user_type !== "warehouse_manager")) {
                const { UserLimit, UserSearch, SelectRegion, OrderSource, FromDate, ToDate, CurrentPage, is_bundle_orders } = OrderManagmentFilter?.WarehouseApprovals;
                
                let param = {
                    "transmit": "broadcast",
                    "url": "order_list",
                    "request": {
                        "status": "SO_APPROVED",
                        "limit": UserLimit,
                        "page_no": CurrentPage,
                        "search": UserSearch.trim(),
                        "from_date": null,
                        "to_date": null,
                        "from_inv": null,
                        "to_inv": null,
                        "order_type": OrderSource,
                        "region_id": SelectRegion,
                        "order_by": sortingfor,
                        "is_bundle_orders": is_bundle_orders,
                    },
                    "DeviceId": device_id
                };
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
                wsSend_request(websocket, param);
            } else if (selfInfo?.user?.user_type === "warehouse_manager") {
                const { UserLimit, UserSearch, SelectRegion, OrderSource, FromDate, ToDate, CurrentPage } = OrderManagmentFilter?.WarehouseApprovals;
                let param = {
                    "transmit": "broadcast",
                    "url": "order_list",
                    "request": {
                        "status": "WH_PENDING",
                        "limit": UserLimit,
                        "page_no": CurrentPage,
                        "search": UserSearch.trim(),
                        "from_date": null,
                        "to_date": null,
                        "from_inv": null,
                        "to_inv": null,
                        "order_type": OrderSource,
                        "region_id": SelectRegion,
                        "order_by": sortingfor,
                    },
                    "DeviceId": device_id
                };
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
                wsSend_request(websocket, param);
            }
        }
    }, [websocket, sortingfor, OrderManagmentFilter?.WarehouseApprovals]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url: "",
            action: false
        }));
    }, []);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'order_code', label: 'Order Id.', type: "navigate_regular_order", navigate_url: `${PO_APPROVALS_URL}`, sort: "order_code", isCollapsable: false },
        { key: 'customer_identity', label: 'Customer Code', type: "label", sort: "customer_identity", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "created_at", isCollapsable: false },
        { key: 'order_source', label: 'Order Source', type: "label", sort: "order_source", isCollapsable: false },
        { key: 'customer_name', label: 'SHIP To Name', type: "label", sort: "customer_name", isCollapsable: false },
        { key: 'billing_id', label: 'BILL To Name', type: "label_get_from_franchise", sort: "billing_id", isCollapsable: false },
        { key: 'qty', label: 'Quantity', type: "label", sort: "qty", isCollapsable: false },
        { key: 'order_priority', label: 'Order Priority', type: "label", sort: "order_priority", isCollapsable: false },
        { key: 'total', label: 'Order Value', type: "label_day_ammount", sort: "total", isCollapsable: false },
        { key: 'updated_at', label: 'Pending Since', type: "pending_since", sort: "updated_at", isCollapsable: false },
        { key: 'priority', label: 'Criticality', type: "label_Criticality", sort: "priority", isCollapsable: false },
        { key: 'order_status', label: 'Status', type: "label_order_status", sort: "order_status", isCollapsable: false },
        { key: '', label: '', type: "labelOrderTypeFoShow", sort: "", isCollapsable: true },
        { key: 'remark', label: 'Remarks', type: "label", sort: "", isCollapsable: true },
        { key: '', label: '', type: "Attachments_SoApprovals", sort: "", isCollapsable: true },
        { key: 'region_id', label: 'Region', type: "label_get_from_list", filterFrom: { list: "regionlist", label: "region_name" }, sort: "", isCollapsable: true },
    ];

    return (<section className="Main_section">
        <MainOrderDataTableFilter 
            filterType={""}
            tabName={"WarehouseApprovals"}
            searchType={"Order Id, Name, Customer Code"}
            FromDateStartDateFlag={true}
            searchdisable={true}
            buttonvisible={false}
            AllOrderStatusAllow={false}
            isAllowWarehouse={false}
            isDateType={false}
            isOdiFilterAllow={false}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(OrderWareHouseApprovalsList?.pagination?.total_records) === OrderWareHouseApprovalsList?.list?.length ? "calc(100vh - 196px)" : parseInt(OrderWareHouseApprovalsList?.pagination?.total_records) > 10 ? "calc(100vh - 235px)" : "calc(100vh - 196px)"
            }}
            srNumber={{
                clickable: true,
                events: "ORDERS_LOG"
            }}
            isCollapsable={true}
            tabName={""} // TAB URL
            PaginationCurrentPage={OrderManagmentFilter["WarehouseApprovals"]?.CurrentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={OrderWareHouseApprovalsList} // ORDER LIST
            Pagination={OrderWareHouseApprovalsList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[3]?.Edit)} // EDITABLE ACCESS STATE
            APIcalling={""}
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
        />
    </section>)
}

export default POApprovals;