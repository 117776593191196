/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Nav, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import DashboardIcon from "../../../assets/img/dashboard_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { SelectMainNavigation, getDarkLightModeFunction } from "../../../redux/actions/adminActions";
import CommonewModel from "../../Common/CommonewModel";
import MainMenuIcon1 from "../../../assets/img/BK/Inventory.svg";
import MainMenuIcon2 from "../../../assets/img/BK/admin-tools.svg";
import MainMenuIcon3 from "../../../assets/img/BK/order-management.svg";
import MainMenuIcon4 from "../../../assets/img/BK/crm-tools.svg";

const SidebarMain = (props) => {
    const { selfInfo, darkmode } = useSelector((state) => state.adminReducers);
    const { menuStates, setMenuStates } = props;
    const Dispatch = useDispatch();
    const { MainNavUrl } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    // clear menu selection and menu select
    const ClearMenuSelection = (url) => {
        Dispatch(SelectMainNavigation(url));
        setMenuStates({
            ...menuStates,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
        });
    };

    // logout function
    const LogoutFnct = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Logout",
            description: `Do you really want to logout ?</h5>`,
            modalType: "not form",
            button: "Yes"
        });
    };

    // dark mode light mode function
    const darkmodeFunction = (url) => {
        Dispatch(getDarkLightModeFunction(url));

        let body = document.getElementById("main_body");

        if(darkmode === undefined || body.className === "") {
            body.classList.add("light");
        } else {
            body?.classList?.replace(body?.className, url);
        };
    };

    useEffect(() => {
        let body = document.getElementById("main_body");

        body.classList.add(darkmode);
    }, [ darkmode ]);

    return(<div className="sidebarMain">
        <Nav className="flex-column">
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DashboardMain?.filter((elm) => elm?.API !== "")?.length > 0 || (PermissionsAccess?.Dashboard?.DailyOrdersRGLBangalore || PermissionsAccess?.Dashboard?.DailyOrdersRGLDelhi || PermissionsAccess?.Dashboard?.DailyOrdersRGLKolkata || PermissionsAccess?.Dashboard?.DealerPerformance || PermissionsAccess?.Dashboard?.OrderFulfillment || PermissionsAccess?.Dashboard?.SalesRevenueReport || PermissionsAccess?.Dashboard?.AllOrderSummery ))) &&
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 100 }}
                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>Dashboard</Tooltip>}
                >
                    <a className={MainNavUrl === "Dashboard" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Dashboard")}>
                        <Image src={DashboardIcon} className="dashboard_icon_main" alt="dashboard" />
                    </a>
                </OverlayTrigger>
            }
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Store?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Warehouse?.filter((elm) => elm?.API !== "")?.length > 0)) &&
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 100 }}
                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>Inventory</Tooltip>}
                >
                    <a className={MainNavUrl === "Inventory" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Inventory")}>
                        <Image src={MainMenuIcon1} alt="menu_icon" />                        
                    </a>
                </OverlayTrigger>
            }
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Masters?.filter((elm) => elm?.API !== "")?.length > 0)) &&
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 100 }}
                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                    Admin Tools
                </Tooltip>}
                >
                    <a className={MainNavUrl === "Admin-Tools" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Admin-Tools")}>
                        <Image src={MainMenuIcon2} alt="menu_icon" />
                    </a>
                </OverlayTrigger>
            }
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.PaymentManager?.filter((elm) => elm?.API !== "")?.length > 0)) &&
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Order Management
              </Tooltip>}
            >
                <a className={MainNavUrl === "Franchise" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Franchise")}>
                    <Image src={MainMenuIcon3} alt="menu_icon" />
                </a>
            </OverlayTrigger>}
            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.ReSells?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.DealerApp?.filter((elm) => elm?.API !== "")?.length > 0) &&
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                CRM Tools
              </Tooltip>}
            >
                <a className={MainNavUrl === "CRM-Tools" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("CRM-Tools")}>
                    <Image src={MainMenuIcon4} alt="menu_icon" />
                </a>
            </OverlayTrigger>}
            {/* <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                {darkmode === undefined ? "Light" : darkmode} Mode
              </Tooltip>}
            >
                <li className="dark_light_mode_btn" >
                    <i className={darkmode === "dark" ? "bi bi-moon active" : "bi bi-moon"} onClick={() => darkmodeFunction("dark")}></i>
                    <i className={(darkmode === "light" || darkmode === undefined) ? "bi bi-brightness-high active" : "bi bi-brightness-high"} onClick={() => darkmodeFunction("light")}></i>
                </li> 
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Logout
              </Tooltip>}
            >
                <li className="Logout_btn" onClick={() => LogoutFnct()}>
                    <i className="bi bi-box-arrow-right"></i>
                </li> 
            </OverlayTrigger> */}
        </Nav>
        <CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />
    </div>)
};

export default SidebarMain;