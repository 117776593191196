/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import CommonTable from "../../Common/CommonTable";
import PaymentDateTableFilter from "../../Common/PaymentDateTableFilter";

const ERPGeneraloutstandingReport = (props) => {
    const [userSearch, setUserSearch] = useState("");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const { device_id, erpAccountList } = useSelector((state) => state.adminReducers);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // get order_list all
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "erp_account",
            "request": {
                "search": userSearch?.trim(),
                "limit": userLimit,
                "page_no": currentPage,
                "franchise_id":"",
                "order_by": "-updated_at",
            },
            "module_name": "erp-general-outstanding-report",
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, [websocket, currentPage, userLimit, userSearch]);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'erp_id', label: 'ERP ID', type: "label", sort: "", isCollapsable: false },
        { key: 'posting_date', label: 'Posting Date', type: "date", sort: "", isCollapsable: false },
        { key: 'created_at', label: 'Date & Time', type: "date_time", sort: "", isCollapsable: false },
        { key: 'franchise_id', label: 'Franchise Code', type: "label_get_from_list_erp_general_summary_details", filterFrom: { list: "franchise", label: "franchise_code" }, sort: "", isCollapsable: false },
        { key: 'franchise_id', label: 'Customer Name', type: "label_get_from_list", filterFrom: { list: "franchise", label: "customer_name" }, sort: "", isCollapsable: false },
        { key: 'region', label: 'Region', type: "label", sort: "", isCollapsable: false },
        { key: '-', label: 'Sales Person', type: "label", sort: "", isCollapsable: false },
        { key: 'invoice_amt', label: 'Inv. Amount', type: "label", sort: "", isCollapsable: false },
        { key: 'range1', label: '0-30', type: "label", sort: "", isCollapsable: false },
        { key: 'range2', label: '31-60', type: "label", sort: "", isCollapsable: false },
        { key: 'range3', label: '61-90', type: "label", sort: "", isCollapsable: false },
        { key: 'range4', label: '91-120', type: "label", sort: "", isCollapsable: false },
        { key: 'range5', label: '121-Above', type: "label", sort: "", isCollapsable: false },
        { key: 'outstanding_amt', label: 'Outstanding', type: "label", sort: "", isCollapsable: false },
        { key: 'paid_amt', label: 'Paid', type: "label", sort: "", isCollapsable: false },
        { key: 'credit_note', label: 'Credit Note', type: "label_price", sort: "", isCollapsable: false },
        { key: 'advance', label: 'Advance', type: "label_price", sort: "", isCollapsable: false },
        { key: 'total_due', label: 'Total Due', type: "label_price", sort: "", isCollapsable: false },
    ];

    return (<section className="Main_section erp_outstanding_main_section">
        <PaymentDateTableFilter 
            searchAllow={true}
            searchType={"Franchise code, ERP ID, Customer Name"}
            userSearch={userSearch} 
            setUserSearch={setUserSearch}
            FromDate={""}
            setFromDate={""}
            ToDate={""}
            setToDate={""}
            setCurrentPage={setCurrentPage}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            filterType={"ErpOutstandingReport"}
            exportBtn={{
                isAllow: false,
                api_url: ""
            }}
            exportSmallBtn={{
                isAllow: true,
                api_url: ""
            }}
            FromDateStartDateFlag={false}
            franchiseFilter={false}
            ButtonSet={{
                buttontitle: "",
                type: "",
                navigate_url: "",
            }}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{ height: parseInt(erpAccountList?.pagination?.total_records) === erpAccountList?.list?.length ? "calc(100vh - 140px)" : erpAccountList?.pagination?.total_records > 10 ? "calc(100vh - 178px)" : "calc(100vh - 140px)" }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={erpAccountList} // ACCOUNT LEDGER REPORT LIST
            Pagination={erpAccountList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={false} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: false,
                label: "edit",
                naviagte: ``
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isTotalAndGrand={{
                isShow: false,
                filterType: "",
                colSpan: 0
            }}
        />
    </section>)
}

export default ERPGeneraloutstandingReport;