/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { UPDATE_POINT_VALUE_SETTINGS, ADD_POINT_VALUE_SETTINGS } from "../Shared/constant";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonTable from "../Common/CommonTable";

const PointSettings = (props) => {
    const {  device_id, selfInfo, PointSettingsList } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[4]?.Edit || PermissionsAccess?.ReSells[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "refurbished_point_value_list",
                "request" : { 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "order_by" :"updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create points setting 
    const cmnFnctPointSettngs = () => {
        Navigate(ADD_POINT_VALUE_SETTINGS);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'start_point', label: 'Start Point', type: "label", sort: "", isCollapsable: false },
        { key: 'end_point', label: 'End Point', type: "label_capitalized", sort: "", isCollapsable: false },
        { key: 'max_percentage', label: 'Max %', type: "label", sort: "", isCollapsable: false },
    ];

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={"Point Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[4]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Point Settings",
                function: cmnFnctPointSettngs
            }}
            searchdisable={false}
            ActiveInactiveFilter={false}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(PointSettingsList?.pagination?.total_records) === PointSettingsList?.list?.length ? "calc(100vh - 186px)" : parseInt(PointSettingsList?.pagination?.total_records) > 10 ? "calc(100vh - 222px)" : "calc(100vh - 186px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={PointSettingsList} // PRODUCT LIST
            Pagination={PointSettingsList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[4]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: (selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[4]?.Edit),
                label: "edit_delete_point",
                naviagte: `${UPDATE_POINT_VALUE_SETTINGS}/`
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
        />
    </section>)
}

export default PointSettings;