/* eslint-disable */
import React, { useContext, useEffect } from "react";
import SearchCommon from "../../Common/SearchCommon";
import CommonDatePicker from "../../Common/CommonDatePicker";
import { Tooltip, OverlayTrigger, Image } from "react-bootstrap";
import generatePDF from "../../Common/generatePDF";
import PDF_Icon from "../../../assets/img/BK/PDF_Icons.svg";
import XLS_Icon from "../../../assets/img/BK/xls_Icons.svg";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import { useSelector } from "react-redux";

const CommonHeader = (props) => {
    const { websocket } = useContext(WebSocketContext);
    const { heading, isSearchAllow, userSearch, setUserSearch, searchType, isSkuTypeAllow, SkuType, setSkuType, isOrderStatusAllow, OrderStatus, setOrderStatus, setCurrentPage, isDatePickerAllow, dateRange, setDateRange, isAllowDownloadPdf, isAllowDownloadExel, isDealerAllow, Dealer, setDealer, isStateAllow, stateSelect, setStateSelect, isRegionAllow, regionSelect, setRegionSelect, isProductAllow, Productselect, setProductselect } = props;
    const { device_id, AllDealerFranchiseTypeListAll, statelistall, Regionlistall, productlistall } = useSelector((state) => state.adminReducers);
    const DealerOptions = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.is_active)?.map((item) => ({ key: item.id, value: item.id, label: item.customer_name }));
    const StateOptions = statelistall?.filter((elm) => elm?.is_active)?.map((item) => ({ key: item.id, value: item.id, label: item.state_name }));
    const RegionOptions = Regionlistall?.filter((elm) => elm?.is_active)?.map((item) => ({ key: item.id, value: item.id, label: item.region_name }));
    const ProductsOptions = productlistall?.filter((elm) => elm?.is_active)?.map((item) => ({ key: item.id, value: item.id, label: item.product_name }));

    // GET ALL FRANCHISE LIST
    useEffect(() => {
        if(isDealerAllow) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // GET ALL STATE LIST
    useEffect(() => {
        if(isStateAllow) {
            let param = { 
                "transmit": "broadcast", 
                "url": "state_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, [ ]);

    // GET ALL REGIONS LIST
    useEffect(() => {
        if(isRegionAllow) {
            let param = { 
                "transmit": "broadcast", 
                "url": "region_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // GET PRODUCT LIST ALL
    useEffect(() => {
        if(isProductAllow) {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // DOWNLOAD PDF FUNCTION
    const DonwloadPDFfile = (id) => {
        if(id === "TransactionHistoryAgeing") {
            generatePDF(id, "Transaction History Ageing");
        };
    };

    // DOWNLOAD EXCEL FUNCTION
    const DownloadExcelfile = (id) => {
        exportToCSV(id);
    };

    // EXPORT TO CSV
    function exportToCSV(filterType) {
        let table = document.getElementById(filterType);
        let rows = table.querySelectorAll('tr');
        let csvContent = "";

        rows.forEach(row => {
            let cols = row.querySelectorAll('th, td');
            let rowData = [];

            cols.forEach(col => {
                let cellText = col.innerText.replace(/,/g, ''); // Escape double quotes
                rowData.push(`${cellText}`); // Wrap each cell in quotes
            });

            csvContent += rowData.join(",") + "\n";
        });

        let blob = new Blob([csvContent], { type: 'text/csv' });
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filterType + '.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return(<div className="DashboardMainHeader">
        <h4>{heading}</h4>
        <div className="MainHeader_filters">
            {/* SEARCH FILTER */}
            {isSearchAllow && (<SearchCommon 
                userSearch={userSearch} 
                setUserSearch={setUserSearch}
                searchType={searchType}
                setCurrentPage={setCurrentPage}
            />)}

            {/* DATEPICKER FILTERS */}
            {isDatePickerAllow && (<CommonDatePicker 
                dateRange={dateRange} 
                setDateRange={setDateRange}
            />)}

            {/* SELECT SKU TYPE */}
            {isSkuTypeAllow && (<select value={SkuType} onChange={(e) => setSkuType(e.target.value)}>
                <option value="">SKU Type</option>
                <option value="E-Bikes">E-Bikes</option>
                <option value="Accessories">Accessories</option>
            </select>)}

            {/* SELECT ORDER STATUS */}
            {isOrderStatusAllow && (<select value={OrderStatus} onChange={(e) => setOrderStatus(e.target.value)}>
                <option value="ST_APPROVED,DISPATCHED,DELIVERED">Order Status</option>
                <option value="ST_APPROVED">Store Approved</option>
                <option value="DISPATCHED">Dispatched</option>
                <option value="DELIVERED">Delivered</option>
            </select>)}

            {/* SELECT PRODUCT  */}
            {isProductAllow && (<CommonReactSelectMenu 
                label={"product"}
                options={ProductsOptions} 
                selectValue={Productselect} 
                setSelectValue={setProductselect} 
                isMendatory={false}
                isAllowClearable={true}
                customWidth={"170px"}
                customeHeight={"32px"}
            />)}

            {/* SELECT FRANCHISE DEALER */}
            {isDealerAllow && (<CommonReactSelectMenu 
                label={"dealer"}
                options={DealerOptions} 
                selectValue={Dealer} 
                setSelectValue={setDealer} 
                isMendatory={false}
                isAllowClearable={true}
                customWidth={"170px"}
                customeHeight={"32px"}
            />)}

            {/* SELECT STATE FILTER */}
            {isStateAllow && (<CommonReactSelectMenu 
                label={"state"}
                options={StateOptions}
                selectValue={stateSelect} 
                setSelectValue={setStateSelect} 
                isMendatory={false}
                isAllowClearable={true}
                customWidth={"170px"}
                customeHeight={"32px"}
            />)}

            {/* SELECT REGION FILTER */}
            {isRegionAllow && (<CommonReactSelectMenu 
                label={"region"}
                options={RegionOptions}
                selectValue={regionSelect}
                setSelectValue={setRegionSelect} 
                isMendatory={false}
                isAllowClearable={true}
                customWidth={"140px"}
                customeHeight={"32px"}
            />)}

            {/* DOWNLOAD PDF */}
            {isAllowDownloadPdf?.open && (<OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Download PDF
            </Tooltip>}>
                <button className="btn downloadfilesBtn exportExcel" onClick={() => DonwloadPDFfile(isAllowDownloadPdf?.id)} >
                    <Image src={PDF_Icon} alt="excel" />
                </button>
            </OverlayTrigger>)}

            {/* DONWLOAD EXCEL */}
            {isAllowDownloadExel?.open && (<OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Download Excel
            </Tooltip>}>
                <button className="btn downloadfilesBtn downloadPdf" onClick={() => DownloadExcelfile(isAllowDownloadExel?.id)} >
                    <Image src={XLS_Icon} alt="excel" />
                </button>
            </OverlayTrigger>)}
        </div>
    </div>)
}

export default CommonHeader;