export const apiMapping = {
    "Dashboard Sales Revenue Report": {
        "View": "sale_revenue_rm, sale_revenue_product, sale_revenue_franchise",
    },
    "Dashboard Transaction History": {
        "View": "erp_account, erp_general_leadger_list",
    },
    "Schemes Metrics": {
        "View": "",
    },
    "Warranty Registration Report": {
        "View": "warranty_register_report, classification_wise_reports, state_vs_warranty_register, product_vs_warranty_register",
    },
    "Product Applications": {
        "Edit": "brand_list, brand_add, brand_edit",
        "View": "brand_list",
    },
    "Category": {
        "Edit": "brand_list, category_list, category_edit, category_add",
        "View": "category_list, brand_list",
    },
    "Sub Category": {
        "Edit": "category_list, subcategory_list, subcategory_edit, subcategory_add",
        "View": "category_list, subcategory_list",
    },
    "Product Group": {
        "Edit": "product_group_list, product_group_edit, product_group_add",
        "View": "product_group_list",
    },
    "Product Creation": {
        "Edit": "brand_list, category_list, box_list, subcategory_list, product_group_list, product_list, product_edit, product_add",
        "View": "product_list",
    },
    "Spares Compatibility": {
        "Edit": "spare_item_list, product_list, spare_item_add, spare_item_edit",
        "View": "spare_item_list",
    },
    "Stock Inward": {
        "Edit": "warehouse_list, product_list, stock_inward_add",
        "View": "warehouse_list, product_list",
    },
    "Stock Level": {
        "Edit": "product_list, warehouse_list, category_list, store_level_list",
        "View": "product_list, warehouse_list, category_list, store_level_list",
    },
    "Stock Details": {
        "Edit": "warehouse_list, product_list, import_data_accessories, import_data",
        "View": "warehouse_list, product_list",
    },
    "Stock Inward Log": {
        "Edit": "product_list, category_list, subcategory_list, warehouse_list, stock_inward_logs, stock_inward_logs, store_frame_list",
        "View": "product_list, category_list, subcategory_list, warehouse_list, stock_inward_logs, stock_inward_logs, store_frame_list",
    },
    "Stock Details Report": {
        "Edit": "product_list, warehouse_list, stock_inward_list, store_frame_list",
        "View": "product_list, warehouse_list, stock_inward_list, store_frame_list",
    },
    "Warehouse": {
        "Edit": "district_list, state_list, region_list, warehouse_list, pin_code_search, warehouse_edit, warehouse_add",
        "View": "district_list, state_list, region_list, warehouse_list",
    },
    "Warehouse Quick Login": {
        "Edit": "warehouse_list, wm_login",
        "View": "warehouse_list",
    },
    "Dispatch List": {
        "Edit": "district_list, state_list, region_list, dispatch_list, state_list, warehouse_list, pin_code_search, dispatch_edit, dispatch_add",
        "View": "district_list, state_list, region_list, dispatch_list, state_list, warehouse_list",
    },
    "Users": {
        "Edit": "region_list, warehouse_list, user_list, user_edit, user_add, franchise_list",
        "View": "region_list, warehouse_list, user_list, franchise_list",
    },
    "Create Franchise": {
        "Edit": "franchise_type_list, franchise_list, region_list, user_list, franchise_list, pin_code_search, franchise_edit, franchise_add, state_list, district_list, get_franchise_order",
        "View": "franchise_type_list, franchise_list, region_list, user_list, franchise_list, state_list, district_list, get_franchise_order",
    },
    "Franchise Type Report": {
        "Edit": "franchise_type_list, franchise_type_edit, franchise_type_add",
        "View": "franchise_type_list",
    },
    "Rm Quick Login": {
        "Edit": "user_list, rm_login",
        "View": "user_list",
    },
    "States": {
        "Edit": "state_list, region_list, state_edit, state_add",
        "View": "state_list",
    },
    "Cities": {
        "Edit": "district_list, district_edit, district_add",
        "View": "district_list",
    },
    "Pincodes": {
        "Edit": "state_list, pin_code_list",
        "View": "state_list, pin_code_list",
    },
    "Box": {
        "Edit": "box_list, box_add, box_edit",
        "View": "box_list",
    },
    "Couriers": {
        "Edit": "courier_list, courier_add, courier_edit",
        "View": "courier_list",
    },
    "Region Master": {
        "Edit": "region_list, region_edit, region_add",
        "View": "region_list",
    },
    "Purchase List": {
        "Edit": "purchase_list, product_list, franchise_list, get_frame_number, pin_code_search, purchase_edit",
        "View": "purchase_list",
    },
    "Purchase Log List": {
        "Edit": "purchase_log_list",
        "View": "purchase_log_list",
    },
    "All Orders Report": {
        "Edit": "region_list, franchise_list, order_list, order_detail, order_logs, warehouse_list",
        "View": "region_list, franchise_list, order_list, order_detail, order_logs, warehouse_list",
    },
    "SO Creation": {
        "Edit": "franchise_list, category_list, subcategory_list, product_list, pin_code_search, order_add, dispatch_list, import_order, warehouse_list, import_fo_order, bundle_list",
        "View": "franchise_list, category_list, subcategory_list, product_list, pin_code_search, dispatch_list, warehouse_list, bundle_list",
    },
    "So Creations (AFS)": {
        "Edit": "franchise_list, category_list, subcategory_list, product_list, pin_code_search, spare_item_list, sale_type_list, afs_order_add",
        "View": "franchise_list, category_list, subcategory_list, product_list, pin_code_search, spare_item_list, sale_type_list",
    },
    "Other Order (AFS)": {
        "Edit": "franchise_list, category_list, subcategory_list, product_list, pin_code_search, spare_item_list, sale_type_list, afs_order_add, ticket_search_by_ticket_number",
        "View": "franchise_list, category_list, subcategory_list, product_list, pin_code_search, spare_item_list, sale_type_list",
    },
    "SO Approvals": {
        "Edit": "region_list, order_list, order_detail, so_approval, order_logs, order_cancelled, order_pause, warehouse_list, store_level_list",
        "View": "region_list, order_list, order_detail, order_logs, warehouse_list",
    },
    "Warehouse Approvals": { 
        "Edit": "region_list, order_list, dispatch_list, warehouse_list, order_detail, wh_approval, order_logs, order_cancelled, wh_order_approval, wh_cancelled",
        "View": "region_list, order_list, order_detail, order_logs, warehouse_list",
    },
    "Pending For Accounts": { 
        "Edit": "region_list, order_list, order_detail, order_logs, order_cancelled, ac_approval, warehouse_list",
        "View": "region_list, order_list, order_logs, warehouse_list",
    },
    "Pending For Stores": { 
        "Edit": "region_list, order_list, order_detail, box_list, st_approval, order_logs, store_frame_list, store_accessories_list, order_cancelled, warehouse_list, store_approval, order_remark, create_erp_invoice",
        "View": "region_list, order_list, order_detail, order_logs, warehouse_list",
    },
    "Pending For Dispatch": { 
        "Edit": "region_list, order_list, order_detail, courier_list, order_dispatch, order_logs, order_cancelled, warehouse_list, courier_list_new, related_courier_list",
        "View": "region_list, order_list, order_detail, order_logs, warehouse_list",
    },
    "Pending For Delivery": { 
        "Edit": "region_list, order_list, order_detail, courier_list, order_delivered, order_logs, warehouse_list",
        "View": "region_list, order_list, order_detail, order_logs, warehouse_list",
    },
    "Order Delivered": { 
        "Edit": "region_list, order_list, order_detail, order_logs, warehouse_list",
        "View": "region_list, order_list, order_detail, order_logs, warehouse_list",
    },
    "Order Rejected": { 
        "Edit": "region_list, order_list, order_logs, warehouse_list",
        "View": "region_list, order_list, order_logs, warehouse_list",
    },
    "Order Cancelled": { 
        "Edit": "region_list, order_list, order_logs, warehouse_list, order_cancelled",
        "View": "region_list, order_list, order_logs, warehouse_list",
    },
    "E-Invoice Details": { 
        "Edit": "invoice_list, order_detail",
        "View": "invoice_list, order_detail",
    },
    "E-Invoice Cancel": { 
        "Edit": "invoice_list, order_detail, invoice_cancel",
        "View": "invoice_list, order_detail",
    },
    "E-Way Bill Report": { 
        "Edit": "invoice_list, order_detail",
        "View": "invoice_list, order_detail",
    },
    "Return And Replace List": { 
        "Edit": "region_list, order_list, order_detail, franchise_list, order_rr, order_logs",
        "View": "region_list, order_list, order_detail, franchise_list, order_logs",
    },
    "Account Ledger Report": { 
        "Edit": "dncn_ledger, dncn_list",
        "View": "dncn_ledger, dncn_list",
    },
    "Debit Credit Notes": { 
        "Edit": "franchise_list, reason_types_list, reason_list, dncn_add",
        "View": "franchise_list",
    },
    "Debit Credit Notes Report": { 
        "Edit": "franchise_list, dncn_list",
        "View": "franchise_list, dncn_list",
    },
    "Payment Approval": { 
        "Edit": "franchise_list, dncn_list, dncn_payment",
        "View": "franchise_list, dncn_list",
    },
    "Gst Data Report": { 
        "Edit": "gst_list",
        "View": "gst_list",
    },
    "Erp Outstanding Report": {
        "Edit": "erp_account, erp_account_leadger_list",
        "View": "erp_account",
    },
    "Tickets": {
        "Edit": "ticket_list, retry_zoho_ticket, region_list, ticket_logs",
        "View": "ticket_list, region_list",
    },
    "Erp General Outstanding Report": {
        "Edit": "erp_account, erp_account_leadger_list, erp_general_leadger_list",
        "View": "erp_account",
    },
    "Resellcycle Reports": {
        "Edit": "refurbished_list",
        "View": "refurbished_list",
    },
    "Resale Brands": {
        "Edit": "refurbished_brand_list, refurbished_brand_add, refurbished_brand_edit",
        "View": "refurbished_brand_list",
    },
    "Settings": {
        "Edit": "refurbished_settings_get, refurbished_settings_update",
        "View": "refurbished_settings_get",
    },
    "Age Value": {
        "Edit": "refurbished_age_list, refurbished_age_add, refurbished_age_edit, refurbished_age_delete",
        "View": "refurbished_age_list",
    },
    "Point List": {
        "Edit": "refurbished_point_list, refurbished_point_add, refurbished_point_edit, refurbished_point_value_list, refurbished_point_value_add, refurbished_point_value_edit, refurbished_point_value_delete",
        "View": "refurbished_point_list, refurbished_point_value_list",
    },
    "Erp Api Failures": {
        "Edit": "erp_order_log_list, erp_po_pr_log_list, retry_erp_apis, retry_erp_po_pr_apis, retry_erp_afs_apis",
        "View": "erp_order_log_list, erp_po_pr_log_list",
    },
    "FBA Orders": {
        "Edit": "fba_order_list, fba_order_detail, fba_order_log_list, retry_fba_orders_erp_invoice_create, fba_orders_erp_invoice_create",
        "View": "fba_order_list, fba_order_log_list",
    },
    "Re Assign warehouse": {
        "Edit": "order_detail, dispatch_list, warehouse_list, wh_reassign",
        "View": "order_detail, dispatch_list, warehouse_list",
    },
    "After Sales Orders": {
        "Edit": "afs_order_list, region_list, afs_order_detail, afs_order_logs, afs_order_delivered, afs_order_dispatch, retry_erp_afs_apis, get_erp_invoice, afs_order_cancelled_reject_return, courier_list, box_list, sale_type_list",
        "View": "afs_order_list, region_list, afs_order_logs, sale_type_list",
    },
    "Sales Person Performance": {
        "Edit": "franchise_scheme_list, admin_scheme_report, franchise_list, import-sales-scheme-target, user_list",
        "View": "franchise_scheme_list, admin_scheme_report, franchise_list",
    },
    "Incentive Slab": {
        "Edit": "salesperson_incentive_slab, incentive_slab_add, delete_incentive_slabs",
        "View": "salesperson_incentive_slab",
    },
    "Schemes": {
        "Edit": "franchise_scheme_list, salesperson_incentive_slab, product_list, product_scheme_add, delete_franchise_scheme",
        "View": "franchise_scheme_list",
    },
    "Unicommerce Api Failures": {
        "Edit": "uni_order_log_list, retry_uni_invoice_ship_status_apis",
        "View": "uni_order_log_list",
    },
    "Regular Order List": {
        "Edit": "franchise_order_list",
        "View": "",
    },
    "Spares Order List": {
        "Edit": "afs_order_list",
        "View": "",
    },
    "Ledger": {
        "Edit": "dealer_erp_account_summary",
        "View": "",
    },
    "Ticket Creation": {
        "Edit": "product_group_list, ticket_add, ticket_list, ticket_logs, retry_zoho_ticket",
        "View": "",
    },
    "Help Desk Category": {
        "Edit": "help_desk_category_list, help_desk_category_add, help_desk_category_edit",
        "View": "help_desk_category_list",
    },
    "Help Desk Sub Category": {
        "Edit": "help_desk_subcategory_list, help_desk_category_list, help_desk_subcategory_add, help_desk_subcategory_edit",
        "View": "help_desk_subcategory_list, help_desk_category_list",
    },
    "Help Desk FAQ": {
        "Edit": "help_desk_faq_list, help_desk_subcategory_list, help_desk_faq_add, help_desk_faq_edit",
        "View": "help_desk_faq_list, help_desk_subcategory_list",
    }
};