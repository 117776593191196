/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import CommonHeader from "./common/CommonHeader";
import { Scrollbars } from 'react-custom-scrollbars-2';
import DashBoardTables from "./common/DashBoardTables";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import CommonDateTime from "../Common/CommonDateTime";

const DashboardSchemesMetrics = () => {
    const [ ProductskuType, setProductSkuType ] = useState("");
    const [ OrderProductStatus, setOrderProductStatus ] = useState("");
    const [ DealerskuType, setDealerSkuType ] = useState("");
    const [ OrderDealerStatus, setOrderDealerStatus ] = useState("");
    const { device_id, DashboardAgingerpAccountList } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userLimit, setUserLimit ] = useState(5);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ dateRange, setDateRange ] = useState([null, null]);
    const [ startDate, endDate ] = dateRange;
    const [ DealerSchemesIncentives, setDealerSchemesIncentives ] = useState({});
    const { websocket } = useContext(WebSocketContext);

    // SALES REVENUE DAILY API CALLING
    // useEffect(() => {
        // DashBoardAPIcalling("erp_account", userSearch, currentPage, userLimit, startDate, endDate);
    // }, [ websocket, userSearch, currentPage, userLimit, endDate ]);
    
    // COMMON API CALLING
    // const DashBoardAPIcalling = (api_url, userSearch, currentPage, userLimit, from_date, to_date) => {
        // let param = {
        //     "transmit": "broadcast",
        //     "url": api_url,
        //     "request": {
        //         "search": userSearch?.trim(),
        //         "limit": userLimit,
        //         "page_no": currentPage,
        //         "from_date": CommonDateTime(from_date, "YYYY-MM-DD", "hide"),
        //         "to_date": CommonDateTime(to_date, "YYYY-MM-DD", "hide"),
        //         "franchise_id":"",
        //         "order_by": "-updated_at",
        //     },
        //     "DeviceId": device_id
        // };
        // wsSend_request(websocket, param);
    // };

    const API_BASE_URL = "https://dummyjson.com/c";
    // DEALING SCHEMES AND INCENTIVES
    useEffect(() => {
        DashBoardAPIcalling(`${API_BASE_URL}/05b1-0ffe-4591-a6ff`, setDealerSchemesIncentives);
    }, []);

    // COMMON API CALLING
    const DashBoardAPIcalling = (api_url, storeStateFunction) => {
        fetch(api_url).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // parses JSON response into native JavaScript objects
        })
        .then(data => {
            storeStateFunction(data);
        })
        .catch(error => {
            console.error('Fetch Error:', error);
        });
    };

    // DEALER SCHEMES & INCENTIVES TABLE COLUMNS ARRAY
    const DealerSchemesAndIncentivesTableColumns = [
        { key: 'franchise_code', label: 'Franchise Code', type: "label", sort: "" },
        { key: 'franchise_name', label: 'Franchise Name', type: "label", sort: "" },
        { key: 'region_name', label: 'Region', type: "label", sort: "" },
        { key: 'active_schemes_count', label: 'Active Schemes', type: "label", sort: "" },
        { key: 'active_associates_count', label: 'Active Associates', type: "label", sort: "" },
    ];

    return(<div className="Main_section dashboard_Main_section" style={{ padding: "0px", backgroundColor: "transparent" }}>
        <div className="dashboard_wrapper">
            <Scrollbars
                style={{
                    height: "calc(100vh - 79px)",
                }}
                renderView={props => <div {...props} className="view" />}
                className="ScrollbarsSidebar"
            >
                {/* Dealer Schemes & Incentives : Table */}
                <div className="dashboard_main_wrapper" style={{ marginBottom: "0px" }}>
                    <CommonHeader 
                        heading={"Dealer Schemes & Incentives"} 
                        isSearchAllow={true}
                        searchType={"Franchise Code, Franchise Name"}
                        userSearch={userSearch}
                        setUserSearch={setUserSearch}
                        isDatePickerAllow={true}
                        dateRange={dateRange} 
                        setDateRange={setDateRange}
                        isSkuTypeAllow={false}
                        SkuType={DealerskuType}
                        setSkuType={setDealerSkuType} 
                        isOrderStatusAllow={false}
                        OrderStatus={OrderDealerStatus} 
                        setOrderStatus={setOrderDealerStatus}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: true,
                            id: "DealerSchemesIncentivesTable"
                        }}
                    />
                    <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ overflow: "auto" }}>
                        <DashBoardTables 
                            isSrNoAllow={true}
                            Columns={DealerSchemesAndIncentivesTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={DealerSchemesIncentives} // ORDER LIST
                            PaginationCurrentPage={""}
                            setPaginationCurrentPage={""}
                            PaginationSet={{
                                current_page: "1", 
                                record_limit: "3",
                            }} // CURRENT TAB PAGINATION
                            isGrandTotalAllow={{
                                allow: false,
                                table_name: "",
                                title: "",
                                colspan: null,
                                data: {},
                            }}
                            height={"320px"}
                            id={"DealerSchemesIncentivesTable"}
                        />
                    </div>
                </div>
            </Scrollbars>
        </div>
    </div>)
};

export default DashboardSchemesMetrics;