/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import CommonHeader from "./common/CommonHeader";
import { Scrollbars } from 'react-custom-scrollbars-2';
import DashBoardTables from "./common/DashBoardTables";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import CommonDateTime from "../Common/CommonDateTime";

const TransactionHistory = () => {
    const [ ProductskuType, setProductSkuType ] = useState("");
    const [ OrderProductStatus, setOrderProductStatus ] = useState("");
    const [ DealerskuType, setDealerSkuType ] = useState("");
    const [ OrderDealerStatus, setOrderDealerStatus ] = useState("");
    const { device_id, DashboardAgingerpAccountList } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userLimit, setUserLimit ] = useState(5);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ dateRange, setDateRange ] = useState([null, null]);
    const [ startDate, endDate ] = dateRange;
    const { websocket } = useContext(WebSocketContext);

    // SALES REVENUE DAILY API CALLING
    useEffect(() => {
        DashBoardAPIcalling("erp_account", userSearch, currentPage, userLimit, startDate, endDate);
    }, [ websocket, userSearch, currentPage, userLimit, endDate ]);
    
    // COMMON API CALLING
    const DashBoardAPIcalling = (api_url, userSearch, currentPage, userLimit, from_date, to_date) => {
        let param = {
            "transmit": "broadcast",
            "url": api_url,
            "request": {
                "search": userSearch?.trim(),
                "limit": userLimit,
                "page_no": currentPage,
                "from_date": CommonDateTime(from_date, "YYYY-MM-DD", "hide"),
                "to_date": CommonDateTime(to_date, "YYYY-MM-DD", "hide"),
                "franchise_id":"",
                "order_by": "-updated_at",
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    };

    // AGEING TRANSACTION HISTORY TABLE COLUMNS ARRAY
    const AgeingTransactionHistoryTableColumns = [
        { key: 'erp_id', label: 'ERP ID', type: "label", sort: "" },
        { key: 'posting_date', label: 'Posting Date', type: "date", sort: "" },
        { key: 'created_at', label: 'Time', type: "date_time", sort: "" },
        { key: 'franchise_id', label: 'Franchise Code', type: "label_get_from_list_modal_open_summery", filterFrom: { list: "franchise", label: "franchise_code" }, sort: "" },
        { key: 'franchise_id', label: 'Customer Name', type: "label_get_from_list", filterFrom: { list: "franchise", label: "customer_name" }, sort: "" },
        { key: 'region', label: 'Region', type: "label", sort: "" },
        { key: '-', label: 'Sales Person', type: "label", sort: "" },
        { key: 'invoice_amt', label: 'Inv. Amount', type: "label", sort: "" },
        { key: 'range1', label: '0-30', type: "label", sort: "" },
        { key: 'range2', label: '31-60', type: "label", sort: "" },
        { key: 'range3', label: '61-90', type: "label", sort: "" },
        { key: 'range4', label: '91-120', type: "label", sort: "" },
        { key: 'range5', label: '121-Above', type: "label", sort: "" },
        { key: 'outstanding_amt', label: 'Outstanding', type: "label", sort: "" },
        { key: 'paid_amt', label: 'Paid', type: "label", sort: "" },
        { key: 'credit_note', label: 'Credit Note', type: "label", sort: "" },
        { key: 'advance', label: 'Advance', type: "label", sort: "" },
        { key: 'total_due', label: 'Total Due', type: "label", sort: "" },
    ];

    return(<div className="Main_section dashboard_Main_section" style={{ padding: "0px", backgroundColor: "transparent" }}>
        <div className="dashboard_wrapper">
            <Scrollbars
                style={{
                    height: "calc(100vh - 79px)",
                }}
                renderView={props => <div {...props} className="view" />}
                className="ScrollbarsSidebar"
            >
                {/* Transaction History : Table */}
                <div className="dashboard_main_wrapper">
                    <CommonHeader 
                        heading={"Transaction History"} 
                        isSearchAllow={false}
                        isDatePickerAllow={false}
                        isSkuTypeAllow={false}
                        SkuType={ProductskuType}
                        setSkuType={setProductSkuType} 
                        isOrderStatusAllow={false}
                        OrderStatus={OrderProductStatus} 
                        setOrderStatus={setOrderProductStatus}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper">
                        
                    </div>
                </div>

                {/* Ageing : Table */}
                <div className="dashboard_main_wrapper" style={{ marginBottom: "0px" }}>
                    <CommonHeader 
                        heading={"Ageing"} 
                        isSearchAllow={true}
                        searchType={"Franchise Code, ERP ID, Customer Name"}
                        userSearch={userSearch}
                        setUserSearch={setUserSearch}
                        isDatePickerAllow={true}
                        dateRange={dateRange} 
                        setDateRange={setDateRange}
                        isSkuTypeAllow={false}
                        SkuType={DealerskuType}
                        setSkuType={setDealerSkuType} 
                        isOrderStatusAllow={false}
                        OrderStatus={OrderDealerStatus} 
                        setOrderStatus={setOrderDealerStatus}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: true,
                            id: "TransactionHistoryAgeing"
                        }}
                        isAllowDownloadExel={{
                            open: true,
                            id: "TransactionHistoryAgeing"
                        }}
                    />
                    <div className="iframe_dashboard_wrapper" style={{ overflow: "auto" }}>
                        <DashBoardTables 
                            isSrNoAllow={true}
                            Columns={AgeingTransactionHistoryTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={DashboardAgingerpAccountList} // ORDER LIST
                            PaginationCurrentPage={currentPage}
                            setPaginationCurrentPage={setCurrentPage}
                            PaginationSet={DashboardAgingerpAccountList?.pagination} // CURRENT TAB PAGINATION
                            height={"283px"}
                            Pagination={""} // CURRENT TAB PAGINATION
                            id={"TransactionHistoryAgeing"}
                        />
                    </div>
                </div>
            </Scrollbars>
        </div>
    </div>)
};

export default TransactionHistory;