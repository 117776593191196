/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Select from 'react-select'
import CheckValid from "../../Common/CheckValid";
import  {setGetMagicCode} from "../../../redux/actions/adminActions.js"
import getMagiccodeAPI from '../../../Api/getMagiccodeAPI';

const WQuickLogin = (props) => {
    const { websocket } = useContext(WebSocketContext);
    const { wareHouselistall, device_id, accessToken, selfInfo } = useSelector((state) => state.adminReducers);
    const [ ApplicationState, setApplicationState ] = useState({
        State:""
    });
    const [ optionsState, SetOptionsState ] = useState([]);
    const [ errorState, seterrorState ] = useState("");
    const dispatch = useDispatch();
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    useEffect(()=>{
        SetOptionsState(wareHouselistall?.filter((elm) => elm?.is_active === true  )?.map((item, index)=>({key:item.id, value:item?.id , label:`${item?.warehouse_code} - ${item?.warehouse_name} - (${item?.display_name})` })))
    },[ wareHouselistall ])

    // get product list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "warehouse_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    const StateValueSelect = (value) => {
        if(value !== null) {
            setApplicationState({...ApplicationState, State: value});
            CheckValid(value, {type: 'StateName', error: seterrorState});
        } else {
            setApplicationState({...ApplicationState, State: ""});
            CheckValid(value === null ? "" : value, {type: 'StateName', error: seterrorState});
        }
    };

    const  getMagicToken =async (value)=>{
        if(value){
            const warehouseCode = value.split(" - ")[0];
            warehouseCode.trim()
            if(value !== undefined ){
                const payload = {
                    device_type:"web",
                    device_id: device_id
                }
                const response = await getMagiccodeAPI(accessToken, payload);
                if(response?.status === 200){
                    dispatch(setGetMagicCode(response?.data));
                    setTimeout(() => {
                        const url = `${process.env.REACT_APP_WH_URL}/wm-login/${warehouseCode}/${response?.data}/${response?.device_id}/web`;
                        window.open(url, '_blank');
                    }, 1000);
                }
            } 
        }
    };

    return(<section className="Main_section">
        <div className="formloginset">
            <div className="row">
                <div className="col-md-4">
                    <Form.Group className="mb-3 " >
                        <Form.Label>Warehouse Code<span className="mendatory_star">*</span></Form.Label>
                        <Form.Group className="mb-3" >
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={ApplicationState?.State}
                                    onChange={(e) => StateValueSelect(e)} 
                                    isClearable 
                                    options={optionsState}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select Warehouse"
                                />
                            </div>
                        </Form.Group>
                    </Form.Group>
                </div>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[1]?.Edit) && (<div className="col-md-1">
                    <Form.Group className="mb-3 " >
                        <Form.Label>&nbsp;</Form.Label>
                        <Button id="whloginhbtn" className="whloginhbtn" onClick={() => getMagicToken(ApplicationState.State?.label)} disabled={!ApplicationState?.State}>
                            Login
                        </Button>
                    </Form.Group>
                </div>)}
            </div>
        </div>
    </section>)
}

export default WQuickLogin;