/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../../../App";
import Select from 'react-select'
import DataTableFilter from "../../Common/DataTableFilter";
import CommonExport from "../../Common/CommonExport";
import CommonTable from "../../Common/CommonTable";

const StockInwardLog = (props) => {
    const { websocket } = useContext(WebSocketContext);
    const { selfInfo, device_id, InwardLogList, wareHouselistall, productlistall } = useSelector((state) => state.adminReducers);
    const [userSearch, setUserSearch] = useState("");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectWarehouse, setselectWarehouse] = useState("");
    const [selectProduct, setselectProduct] = useState("");
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} - (${item?.warehouse_code})- (${item?.display_name})` }));
    const optionsProducts = productlistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.product_name }));
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "product_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get category all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "subcategory_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all city list
    useEffect(() => {
        if (selfInfo?.user?.user_type === 'super_admin') {
            let param = {
                "transmit": "broadcast",
                "url": "warehouse_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        }
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[3]?.Edit || PermissionsAccess?.Store[3]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "stock_inward_logs",
                "request": {
                    "status": "",
                    "limit": userLimit,
                    "page_no": currentPage,
                    "search": userSearch.trim(),
                    "warehouse_id": selectWarehouse,
                    "product_id": selectProduct,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // get product list
    useEffect(() => {
        APICall();
    }, [ websocket ,userSearch, currentPage, userLimit, selectWarehouse, selectProduct ]);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // wareehouse select
    const WareHouseSelect = (value) => {
        setselectWarehouse(value?.value);
        setCurrentPage(1);
    };

    // Product select
    const ProductSelect = (value) => {
        setselectProduct(value?.value);
        setCurrentPage(1);
    };

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'inward_id', label: 'Txn ID', type: "label", sort: "", isCollapsable: false },
        { key: 'created_at', label: 'Date & Time', type: "date", sort: "", isCollapsable: false },
        { key: 'product_id', label: 'Product Name', type: "label_get_from_list", filterFrom: { list: "product", label: "product_name" }, sort: "", isCollapsable: false },
        { key: 'qty', label: 'Qty', type: "label", sort: "", isCollapsable: false },
        { key: 'author', label: 'Auther Name', type: "label_get_from_list", filterFrom: { list: "user_list", label: "full_name" }, sort: "", isCollapsable: false },
        { key: 'bill_no', label: 'Billing No', type: "label", sort: "", isCollapsable: false },
        { key: 'warehouse_id', label: 'Warehouse Name', type: "label_get_from_list", filterFrom: { list: "warehouse", label: "display_name" }, sort: "", isCollapsable: false },
        { key: 'product_id', label: 'Category', type: "label_get_category_name_from_product", sort: "", isCollapsable: false },
        { key: 'erp_invoice', label: 'Erp Invoice', type: "label", sort: "", isCollapsable: false },
        { key: 'type_of_transaction', label: 'Type Of Transaction', type: "label", sort: "", isCollapsable: false },
        { key: 'stock_action', label: 'Stock Action', type: "label", sort: "", isCollapsable: false },
    ];

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    onChange={(e) => ProductSelect(e)}
                                    isClearable
                                    options={optionsProducts}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Products"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    {selfInfo?.user?.user_type === 'super_admin' && (<div className="col-md-3">
                        <Form.Group className="mb-3">
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    onChange={(e) => WareHouseSelect(e)}
                                    isClearable
                                    options={optionsWarehouse}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Warehouse"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>)}
                    <div className="col-md-3">
                        <button className="btn exportbtn" style={{ width: "auto" }} onClick={() => CommonExport("stock_inward_log", { "product_id": selectProduct, "warehouse_id": selectWarehouse, "limit": userLimit, "search": userSearch.trim(), "status":"", "page_no": currentPage })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter
            filterType={""}
            searchType={"Inward ID"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(InwardLogList?.pagination?.total_records) === InwardLogList?.list?.length ? "calc(100vh - 207px)" : parseInt(InwardLogList?.pagination?.total_records) > 10 ? "calc(100vh - 245px)" : "calc(100vh - 207px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={InwardLogList} // PRODUCT LIST
            Pagination={InwardLogList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[0]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: true,
                url: "stock-inward-log",
            }}
        />
    </section>)
}

export default StockInwardLog;