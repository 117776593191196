import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CommonDatePicker = (props) => {
    const { dateRange, setDateRange } = props;
    const [ startDate, endDate ] = dateRange;
    let today = new Date();
    
    return(<div className="datepickField_new min">
        <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
                setDateRange(update);
            }}
            maxDate={today}
            placeholderText={"Select start date & end date"}
            dateFormat="dd MMM, yyyy"
            isClearable={true}
        />
    </div>)
};

export default CommonDatePicker;