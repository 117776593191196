/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../Common/DataTableFilter";
import { useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { ADD_FRANCHISE_TYPE_REPORT_URL } from "../Shared/constant";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonTable from "../Common/CommonTable";

const FranchiseType = (props) => {
    const { FranchiseTypeList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };


    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[2]?.Edit || PermissionsAccess?.AdminTools[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_type_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // create applications
    const cmnFnct = () => {
        Navigate(ADD_FRANCHISE_TYPE_REPORT_URL);
    };

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'franchise_type_name', label: 'Franchise Type Name', type: "label", sort: "", isCollapsable: false },
        { key: 'franchise_type_credit', label: 'Franchise Type Credit', type: "label", sort: "", isCollapsable: false },
        { key: 'is_active', label: 'Status', type: "status_active", sort: "", isCollapsable: false }
    ];

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Franchise Type"}
            searchType={"Franchise Type Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[2]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Franchise Type",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{ height: FranchiseTypeList?.pagination?.total_records > 10 ? "calc(100vh - 157px)" : "calc(100vh - 140px)"}} 
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={FranchiseTypeList} // CATEGORY LIST
            Pagination={FranchiseTypeList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[2]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[2]?.Edit,
                label: "edit",
                naviagte: `/edit-franchise-type-report/`
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
        />
    </section>)
}

export default FranchiseType;