import React, { useState } from "react";
import { Form, Image, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonExport from "./CommonExport";
import Select from 'react-select';
import { useSelector } from "react-redux";
import AddMoreIcon from "../../assets/img/BK/add_btn_icon.svg";
import SearchIcon from "../../assets/img/BK/table_search_box.png";
import ExelIcon from "../../assets/img/BK/XLS_icon.svg";
import CommonewModel from "./CommonewModel";
import ExportToCSV from "./ExportToCSV";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";

const PaymentDateTableFilter = (props) => {
    const { FromDate, setFromDate, ToDate, setToDate, setCurrentPage, userLimit, setUserLimit, exportBtn, FromDateStartDateFlag, Franchise, setFranchise, filterType, franchiseFilter, ButtonSet, exportSmallBtn, searchAllow, userSearch, setUserSearch, searchType } = props;
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ CommonSearchUpdate, setCommonSearchUpdate ] = useState("");
    const { AllDealerFranchiseTypeListAll } = useSelector((state) => state.adminReducers);
    const FranchiseListOption = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.is_active === true && elm?.parent_id === null)?.map((item, index) => ({ ...item, key: item.id, value: item?.id, label: item?.customer_name }))
    const today = new Date();
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // export files
    const ExportBtn = (export_data) => {
        const { api_url } = export_data;
        let filterOptions = {};

        if(filterType === "Account-Ledger-Report") {
            filterOptions.FromDate = FromDate;
            filterOptions.ToDate = ToDate;
            filterOptions.limit = userLimit;
        } else if(filterType === "Debit-credit-note-report") {
            filterOptions.FromDate = FromDate;
            filterOptions.ToDate = ToDate;
            filterOptions.limit = userLimit;
            filterOptions.franchise_id = Franchise?.value;
        };

        CommonExport(api_url, filterOptions);
    };

    // export small button
    const ExportSmallBtn = () => {
        ExportToCSV(filterType);
    };

    // franchise select fucntion
    const FranchiseSelect = (value) => {
        if (value !== null) {
            setFranchise(value);
        } else {
            setFranchise("");
        };
    };

    // select limit
    const SelectLimitFct = (e) => {
        setUserLimit(parseInt(e.target.value));
        setCurrentPage(1);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
        setCommonSearchUpdate("");
    };

    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            setCurrentPage(1);
            OnEnterSearch();
        };
    };

    // search box
    const OnEnterSearch = () => {
        setUserSearch(CommonSearchUpdate);
    };

    // create and edit function 
    const AddCommonFunction = () => {
        if(ButtonSet?.type === "modal_open") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Create New Payment",
                subtitle: "Create New Payment",
                modalType: "form",
                button: "Create"
            })
        };
    };

    // clear after delete button hit
    document.addEventListener('keydown', function(event) {
        var keyPressed = event.key;
        if (keyPressed === 'Delete') {
            ClearSearchFiled();
        }
    });

    return(<div className="card-header-New card-header-Filter-New" style={{ width: "100%" }}>
        <div className="row">
            <div className="col-md-8">
                <div className="row">
                    {searchAllow && (<div className="col-md-4" >
                        <div className={`searchBoxwithbtn ${userSearch !== ""?"search":""}`}>
                            <input 
                                type="text" 
                                className="form-control" 
                                value={CommonSearchUpdate} 
                                onKeyDown={(e) => {onEnterSearch(e); EmptySpaceFieldValid(e)}} 
                                onChange={(e) => setCommonSearchUpdate(e.target.value)} 
                                placeholder={`Search ...`}
                            />
                            <Button className="searchbtn" onClick={() => OnEnterSearch()}>
                                <Image src={SearchIcon} alt="search_btn" />
                            </Button>
                            {userSearch !== "" && (
                                <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 100 }}
                                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                Clear the search by using the delete key.
                            </Tooltip>}
                            >
                                <i className="bi bi-x-circle-fill searchclear" onClick={() => ClearSearchFiled()}></i>
                            </OverlayTrigger>)}
                            
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 100 }}
                                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                Search using {searchType ? `(${searchType})`:""}
                            </Tooltip>}
                            >
                                <i className="searchInfo bi bi-info-circle-fill"></i>
                            </OverlayTrigger>
                        </div>
                    </div>)}
                    {FromDateStartDateFlag && (<React.Fragment>
                        <div className="col-md-3" >
                            <Form.Group>
                                <div className="datepickField min">
                                    <DatePicker
                                        selected={FromDate}
                                        startDate={FromDate}
                                        endDate={ToDate}
                                        onChange={(date) => DateStartGet(date)}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={today}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter From Date"
                                        isClearable={FromDate !== "" ? true : false}
                                        name="statDate"
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" >
                            <Form.Group>
                                <div className="datepickField">
                                    <DatePicker
                                        selected={ToDate}
                                        startDate={FromDate}
                                        endDate={ToDate}
                                        minDate={startDate}
                                        maxDate={endDate}
                                        onChange={(date) => DateEndGet(date)}
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter To Date"
                                        isClearable={ToDate !== "" ? true : false}
                                        name="ToDate"
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                    </React.Fragment>)}
                    {franchiseFilter && (<div className="col-md-4" >
                        <Form.Group>
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    onChange={(e) => FranchiseSelect(e)}
                                    isClearable
                                    options={FranchiseListOption}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Franchise"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>)}
                    {exportBtn?.isAllow && (<div className="col-md-2" style={{ width: "auto" }}>
                        <button 
                            className="btn exportbtn" 
                            disabled={ToDate === "" || FromDate === ""} 
                            style={{ width: "auto" }} 
                            onClick={() => ExportBtn(exportBtn)}
                        >
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>)}
                </div>
            </div>
            <div className="col-md-4" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <div className="card-header-right">
                    <select className="form-control userLimitselect" value={userLimit} onChange={(e) => SelectLimitFct(e)}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                    </select> 
                </div>
                {ButtonSet?.buttontitle !== "" && (<button className="btn head_cmn_btn" onClick={() => AddCommonFunction()}>
                    <Image src={AddMoreIcon} alt="addmore" />
                    {ButtonSet?.buttontitle}
                </button>)}
                {exportSmallBtn?.isAllow && (<OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 100 }}
                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                    Download Excel
                </Tooltip>}>
                    <button className="btn exportbtnMain" id="exportbtnMain" onClick={() => ExportSmallBtn()} >
                        <Image src={ExelIcon} alt="excel" />
                    </button>
                </OverlayTrigger>)}
            </div>
        </div>
        {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
    </div>)
}

export default PaymentDateTableFilter;