import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';

const CommonScrollBar = (props) => {
    const { className, height, children } = props;

    return(<Scrollbars 
        style={{ height: height?.height}}   
        renderView={props => <div {...props} className="view"/>}
        className={className !== "" ? className : "ScrollbarsSidebar"}
    >
        {children}
    </Scrollbars>)
};

export default CommonScrollBar;