/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import CommonHeader from "./common/CommonHeader";
import DashBoardCards from "./common/DashBoardCards";
import DashBoardTables from "./common/DashBoardTables";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import CommonScrollBar from "../Common/CommonScrollBar";

const SalesRevenueReport = () => {
    const { websocket } = useContext(WebSocketContext);
    const { device_id, DashboardsalesRevenueMonthly, DashboardsalesRevenueDaily, DashboardProductRevenueMonthly, DashboardProductRevenueDailly, DashboardDealerRevenueMonthly, DashboardDealerRevenueDailly } = useSelector((state) => state.adminReducers);
    const [ userLimit, setUserLimit ] = useState(5);

    // Sales Revenue: Regional Manager State
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ currentPagedaily, setCurrentPagedaily ] = useState(1);
    const [ skuType, setSkuType ] = useState("E-Bikes");
    const [ OrderStatus, setOrderStatus ] = useState("ST_APPROVED,DISPATCHED,DELIVERED");

    // Sales Revenue: Product State
    const [ currentProductPage, setCurrentProductPage ] = useState(1);
    const [ currentProductPagedaily, setCurrentProductPagedaily ] = useState(1);
    const [ ProductskuType, setProductSkuType ] = useState("E-Bikes");
    const [ OrderProductStatus, setOrderProductStatus ] = useState("ST_APPROVED,DISPATCHED,DELIVERED");
    
    // Sales Revenue: Dealer State
    const [ currentDealerPage, setCurrentDealerPage ] = useState(1);
    const [ currentDealerPagedaily, setCurrentDealerPagedaily ] = useState(1);
    const [ DealerskuType, setDealerSkuType ] = useState("E-Bikes");
    const [ OrderDealerStatus, setOrderDealerStatus ] = useState("ST_APPROVED,DISPATCHED,DELIVERED");

    // SALES REVENUE : Regional Manager MONTHLY API CALLING
    useEffect(() => {
        DashBoardAPIcalling("sale_revenue_rm", OrderStatus, userLimit, currentPage, skuType, "monthly");
    }, [ websocket, OrderStatus, currentPage, userLimit, skuType ]);

    // SALES REVENUE : Regional Manager DAILY API CALLING
    useEffect(() => {
        DashBoardAPIcalling("sale_revenue_rm", OrderStatus, userLimit, currentPagedaily, skuType, "daily");
    }, [ websocket, OrderStatus, currentPagedaily, userLimit, skuType ]);

    // SALES REVENUE : Product MONTHLY API CALLING
    useEffect(() => {
        DashBoardAPIcalling("sale_revenue_product", OrderProductStatus, userLimit, currentProductPage, ProductskuType, "monthly");
    }, [ websocket, OrderProductStatus, currentProductPage, userLimit, ProductskuType ]);

    // SALES REVENUE : Product DAILY API CALLING
    useEffect(() => {
        DashBoardAPIcalling("sale_revenue_product", OrderProductStatus, userLimit, currentProductPagedaily, ProductskuType, "daily");
    }, [ websocket, OrderProductStatus, currentProductPagedaily, userLimit, ProductskuType ]);

    // SALES REVENUE : Dealer MONTHLY API CALLING
    useEffect(() => {
        DashBoardAPIcalling("sale_revenue_franchise", OrderDealerStatus, userLimit, currentDealerPage, DealerskuType, "monthly");
    }, [ websocket, OrderDealerStatus, currentDealerPage, userLimit, DealerskuType ]);

    // SALES REVENUE : Dealer DAILY API CALLING
    useEffect(() => {
        DashBoardAPIcalling("sale_revenue_franchise", OrderDealerStatus, userLimit, currentDealerPagedaily, DealerskuType, "daily");
    }, [ websocket, OrderDealerStatus, currentDealerPagedaily, userLimit, DealerskuType ]);


    // COMMON API CALLING
    const DashBoardAPIcalling = (api_url, status, limit, page_no, sku_type, filter_type) => {
        let param = {
            "transmit": "broadcast",
            "url": api_url,
            "request": {
                "status_list": status,
                "limit": limit,
                "page_no": page_no,
                "sku_type": sku_type,
                "filter_type": filter_type,
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    };

    // DAILY MONTHLY SALES REVENUE REGIONAL MANAGER TABLE COLUMNS ARRAY
    const DailyMonthlySalesRevenueTableColumns = [
        { key: 'regional_manager', label: 'Regional Manager', type: "label", sort: "" },
        { key: 'current_quantity', label: 'Quantity', type: "label", sort: "" },
        { key: 'qty_percentage', label: '', type: "label_percentage", sort: "" },
        { key: 'current_total_amount', label: 'Total Amount', type: "label", sort: "" },
        { key: 'total_amount_percentage', label: '', type: "label_percentage", sort: "" }
    ];

    // DAILY MONTHLY SALES REVENUE PRODUCT TABLE COLUMNS ARRAY
    const DailyMonthlySalesRevenueProductTableColumns = [
        { key: 'product_name', label: 'Product Name', type: "label", sort: "" },
        { key: 'quantity', label: 'Quantity', type: "label", sort: "" },
        { key: 'qty_percentage', label: '', type: "label_percentage", sort: "" },
        { key: 'total_amount', label: 'Total Amount', type: "label", sort: "" },
        { key: 'amount_percentage', label: '', type: "label_percentage", sort: "" }
    ];

    // DAILY MONTHLY SALES REVENUE DEALER TABLE COLUMNS ARRAY
    const DailyMonthlySalesRevenueDealerTableColumns = [
        { key: 'franchise_name', label: 'Dealer Name', type: "label", sort: "" },
        { key: 'franchise_code', label: 'Dealer Code', type: "label", sort: "" },
        { key: 'current_quantity', label: 'Quantity', type: "label", sort: "" },
        { key: 'qty_percentage', label: '', type: "label_percentage", sort: "" },
        { key: 'current_total_amount', label: 'Total Amount', type: "label", sort: "" },
        { key: 'total_amount_percentage', label: '', type: "label_percentage", sort: "" }
    ];

    return(<div className="Main_section dashboard_Main_section" style={{ padding: "0px", backgroundColor: "transparent" }}>
        <div className="dashboard_wrapper">
            <CommonScrollBar height={{ height: "calc(100vh - 62px)" }}>
                {/* Daily - Monthly Sales Revenue : Regional Manager Table */}
                <div className="dashboard_main_wrapper">
                    <CommonHeader 
                        heading={"Daily - Monthly Sales Revenue : Regional Manager"} 
                        isSearchAllow={false}
                        isDatePickerAllow={false}
                        isSkuTypeAllow={true}
                        SkuType={skuType}
                        setSkuType={setSkuType} 
                        isOrderStatusAllow={true}
                        OrderStatus={OrderStatus} 
                        setOrderStatus={setOrderStatus}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper">
                        <div className="row">
                            <div className="col-md-6" style={{paddingRight: "5px"}}>
                                <h4 className="Monthly_heading Monthly">Monthly</h4>
                                <div className="card_wrapper Monthly">
                                    <div className="row">
                                        <DashBoardCards 
                                            columns={6}
                                            icons={"cycle"} 
                                            label={"Unit Sold"} 
                                            total_value={DashboardsalesRevenueMonthly?.main_total?.current_quantity === undefined ? 0 : DashboardsalesRevenueMonthly?.main_total?.current_quantity}
                                            compaared={"last month"}
                                            compaaredPercantage={DashboardsalesRevenueMonthly?.main_total?.total_qty_percentage === undefined ? 0 : DashboardsalesRevenueMonthly?.main_total?.total_qty_percentage}
                                            type={"Qty"}
                                            style={{paddingRight: "5px"}}
                                        />
                                        <DashBoardCards 
                                            columns={6}
                                            icons={"Revenue"} 
                                            label={"Total Revenue"} 
                                            total_value={DashboardsalesRevenueMonthly?.main_total?.current_total_amount === undefined ? 0 : DashboardsalesRevenueMonthly?.main_total?.current_total_amount}
                                            compaared={"last month"}
                                            compaaredPercantage={DashboardsalesRevenueMonthly?.main_total?.total_amount_percentage === undefined ? 0 : DashboardsalesRevenueMonthly?.main_total?.total_amount_percentage}
                                            type={"Amount"}
                                            style={{paddingLeft: "5px"}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{paddingLeft: "5px"}}>
                                <h4 className="Monthly_heading Today">Today</h4>
                                <div className="card_wrapper Today">
                                    <div className="row">
                                        <DashBoardCards 
                                            columns={6}
                                            icons={"cycle"} 
                                            label={"Unit Sold"} 
                                            total_value={DashboardsalesRevenueDaily?.main_total?.current_quantity === undefined ? 0 : DashboardsalesRevenueDaily?.main_total?.current_quantity}
                                            compaared={"yesterday"}
                                            compaaredPercantage={DashboardsalesRevenueDaily?.main_total?.total_qty_percentage == undefined ? 0 : DashboardsalesRevenueDaily?.main_total?.total_qty_percentage}
                                            type={"Qty"}
                                            style={{paddingRight: "7px"}}
                                        />
                                        <DashBoardCards 
                                            columns={6}
                                            icons={"Revenue"} 
                                            label={"Total Revenue"} 
                                            total_value={DashboardsalesRevenueDaily?.main_total?.current_total_amount === undefined ? 0 : DashboardsalesRevenueDaily?.main_total?.current_total_amount}
                                            compaared={"yesterday"}
                                            compaaredPercantage={DashboardsalesRevenueDaily?.main_total?.total_amount_percentage === undefined ? 0 : DashboardsalesRevenueDaily?.main_total?.total_amount_percentage}
                                            type={"Amount"}
                                            style={{paddingLeft: "7px"}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{paddingRight: "5px"}}>
                                <DashBoardTables 
                                    isSrNoAllow={true}
                                    Columns={DailyMonthlySalesRevenueTableColumns} // TABLE COLLUMNS & ROWS
                                    OrderList={DashboardsalesRevenueMonthly} // ORDER LIST
                                    PaginationCurrentPage={currentPage}
                                    setPaginationCurrentPage={setCurrentPage}
                                    PaginationSet={DashboardsalesRevenueMonthly?.pagination} // CURRENT TAB PAGINATION
                                    height={"283px"}
                                    id={"SalesRevenueMonthly"}
                                />
                            </div>
                            <div className="col-md-6" style={{paddingLeft: "5px"}}>
                                <DashBoardTables 
                                    isSrNoAllow={true}
                                    Columns={DailyMonthlySalesRevenueTableColumns} // TABLE COLLUMNS & ROWS
                                    OrderList={DashboardsalesRevenueDaily} // ORDER LIST
                                    PaginationCurrentPage={currentPagedaily}
                                    setPaginationCurrentPage={setCurrentPagedaily}
                                    PaginationSet={DashboardsalesRevenueDaily?.pagination} // CURRENT TAB PAGINATION
                                    height={"283px"}
                                    id={"SalesRevenueDaily"}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Daily - Monthly Sales Revenue : Product Table */}
                <div className="dashboard_main_wrapper">
                    <CommonHeader 
                        heading={"Daily - Monthly Sales Revenue : Product"} 
                        isSearchAllow={false}
                        isDatePickerAllow={false}
                        isSkuTypeAllow={true}
                        SkuType={ProductskuType}
                        setSkuType={setProductSkuType}
                        isOrderStatusAllow={true} 
                        OrderStatus={OrderProductStatus} 
                        setOrderStatus={setOrderProductStatus}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper">
                        <div className="row">
                            <div className="col-md-6" style={{paddingRight: "5px"}}>
                                <h4 className="Monthly_heading Monthly">Monthly</h4>
                                <DashBoardTables 
                                    isSrNoAllow={true}
                                    Columns={DailyMonthlySalesRevenueProductTableColumns} // TABLE COLLUMNS & ROWS
                                    OrderList={DashboardProductRevenueMonthly} // ORDER LIST
                                    PaginationCurrentPage={currentProductPage}
                                    setPaginationCurrentPage={setCurrentProductPage}
                                    PaginationSet={DashboardProductRevenueMonthly?.pagination} // CURRENT TAB PAGINATION
                                    height={"320px"}
                                    isGrandTotalAllow={{
                                        allow: DashboardProductRevenueMonthly?.list?.length > 0,
                                        table_name: "Monthly-Sales-Revenue-Product",
                                        title: "Grand Total",
                                        colspan: null,
                                        data: DashboardProductRevenueMonthly,
                                    }}
                                    id={"ProductRevenueMonthly"}
                                />
                            </div>
                            <div className="col-md-6" style={{paddingLeft: "5px"}}>
                                <h4 className="Monthly_heading Today">Today</h4>
                                <DashBoardTables 
                                    isSrNoAllow={true}
                                    Columns={DailyMonthlySalesRevenueProductTableColumns} // TABLE COLLUMNS & ROWS
                                    OrderList={DashboardProductRevenueDailly} // ORDER LIST
                                    PaginationCurrentPage={currentProductPagedaily}
                                    setPaginationCurrentPage={setCurrentProductPagedaily}
                                    PaginationSet={DashboardProductRevenueDailly?.pagination} // CURRENT TAB PAGINATION
                                    height={"283px"}
                                    isGrandTotalAllow={{
                                        allow: DashboardProductRevenueDailly?.list?.length > 0,
                                        table_name: "Monthly-Sales-Revenue-Product",
                                        title: "Grand Total",
                                        colspan: null,
                                        data: DashboardProductRevenueDailly,
                                    }}
                                    id={"ProductRevenueDaily"}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Daily - Monthly Sales Revenue : Dealer Table */}
                <div className="dashboard_main_wrapper" style={{ marginBottom: "0px" }}>
                    <CommonHeader 
                        heading={"Daily - Monthly Sales Revenue : Dealer"} 
                        isSearchAllow={false}
                        isDatePickerAllow={false}
                        isSkuTypeAllow={true}
                        SkuType={DealerskuType}
                        setSkuType={setDealerSkuType} 
                        isOrderStatusAllow={true}
                        OrderStatus={OrderDealerStatus} 
                        setOrderStatus={setOrderDealerStatus}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper">
                        <div className="row">
                            <div className="col-md-6" style={{paddingRight: "5px"}}>
                                <h4 className="Monthly_heading Monthly">Monthly</h4>
                                <DashBoardTables 
                                    isSrNoAllow={true}
                                    Columns={DailyMonthlySalesRevenueDealerTableColumns} // TABLE COLLUMNS & ROWS
                                    OrderList={DashboardDealerRevenueMonthly} // ORDER LIST
                                    PaginationCurrentPage={currentDealerPage}
                                    setPaginationCurrentPage={setCurrentDealerPage}
                                    PaginationSet={DashboardDealerRevenueMonthly?.pagination} // CURRENT TAB PAGINATION
                                    height={"283px"}
                                    id={"DealerRevenueMonthly"}
                                />
                            </div>
                            <div className="col-md-6" style={{paddingLeft: "5px"}}>
                                <h4 className="Monthly_heading Today">Today</h4>
                                <DashBoardTables 
                                    isSrNoAllow={true}
                                    Columns={DailyMonthlySalesRevenueDealerTableColumns} // TABLE COLLUMNS & ROWS
                                    OrderList={DashboardDealerRevenueDailly} // ORDER LIST
                                    PaginationCurrentPage={currentDealerPagedaily}
                                    setPaginationCurrentPage={setCurrentDealerPagedaily}
                                    PaginationSet={DashboardDealerRevenueDailly?.pagination} // CURRENT TAB PAGINATION
                                    height={"283px"}
                                    id={"DealerRevenueDaily"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CommonScrollBar>
        </div>
    </div>)
};

export default SalesRevenueReport;