/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import CommonTable from "../../Common/CommonTable";

const EInvoiceDetails = (props) => {
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const { device_id, EInvoiceSuccess } = useSelector((state) => state.adminReducers);
    const [ Tab, setTab ] = useState("SUCCESS");

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        if(websocket){
           callFilterData();
        }
    }, [ websocket, userSearch, currentPage, userLimit, Tab ]);

    const callFilterData = () => {
        let param = {
            "transmit": "broadcast",
            "url": "invoice_list",
            "request" : {
                limit: userLimit,
                page_no: currentPage,
                search: userSearch.trim(),
                ewb_status: "",
                is_cancel: false,
                order_by: "-updated_at",
                invoice_status: Tab,
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    // TABLE COLUMNS ARRAY
    let TableColumns = [
        { key: 'order_code', label: 'Order Id', type: "label", sort: "", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "", isCollapsable: false },
        { key: 'invoice_no', label: 'Invoice ID', type: "invoice_no", sort: "", isCollapsable: false },
        { key: 'updated_at', label: 'Invoice date', type: "date", sort: "", isCollapsable: false },
        { key: 'ack_no', label: 'EWB No.', type: "label", sort: "", isCollapsable: false },
        { key: 'ack_date', label: 'EWB Date.', type: "date", sort: "", isCollapsable: false },
    ];

    if(Tab === "FAILURE") {
        const TableColumnsFailure = [
            { key: '', label: 'Error', type: "label_E_Invoice_error_view", sort: "", isCollapsable: false },
            { key: '', label: 'Generate', type: "label_Generate_Invoice", sort: "", isCollapsable: false },
        ];
        TableColumns = [...TableColumns, ...TableColumnsFailure ];
    };

    return(<section className="Main_section">
        <div className="MainHeader">
            <ul className="MainHeader_Tab">
                <li className={Tab === "SUCCESS" ? "active" : ""} onClick={() => setTab("SUCCESS")}>Generate</li>
                <li className={Tab === "FAILURE" ? "active" : ""} onClick={() => setTab("FAILURE")}>Pending</li>
            </ul>
        </div>
        <DataTableFilter 
            searchType={"Invoice ID."}
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(EInvoiceSuccess?.pagination?.total_records) === EInvoiceSuccess?.list?.length ? "calc(100vh - 185px)" : parseInt(EInvoiceSuccess?.pagination?.total_records) > 10 ? "calc(100vh - 221px)" : "calc(100vh - 185px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={EInvoiceSuccess} // ORDER LIST
            Pagination={EInvoiceSuccess?.pagination} // CURRENT TAB PAGINATION
            EditAccess={false} // EDITABLE ACCESS STATE
            APIcalling={""}
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
        />
    </section>)
}

export default EInvoiceDetails;