/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { ClearFormSetFutios, StartMainCommonLoader } from "../../../redux/actions/adminActions";
import CommonLoader from "../../Common/CommonLoader";
import CommonStatus from "../../Common/CommonStatus";

const GstDataReport = (props) => {
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const today = new Date();
    const { device_id, ClearFormSet, gstReportList, commonMainLoader } = useSelector((state) => state.adminReducers);

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setFromDate("");
            setToDate("");
        } else {
            setToDate("");
            setFromDate(date);
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            setToDate("");
        } else {
            setToDate(date);
        }
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            callFilterData()
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ ClearFormSet ]);

    useEffect(() => {
        if (websocket) {
            callFilterData();
        };
    }, [websocket, ToDate, currentPage, userLimit]);

    const callFilterData = () => {
        Dispatch(StartMainCommonLoader({
            url: "gst_list",
            loader: true
        }));
        let param = {
            "transmit": "broadcast",
            "url": "gst_list",
            "request" : { 
                "status" : "", 
                "limit" : userLimit, 
                "page_no" : currentPage,
                "from_date": null, 
                "to_date": null
            }, 
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    return (<section className="Main_section Main_sectionGSTReports" style={{ position: "relative" }}>
         <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee", marginBottom: "10px" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={FromDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={ToDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={FromDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter
            searchType={"Invoice ID."}
            filterType={"GSTreport"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={false}
        />
        <div className="tableView gstreporttableadded">
            <Scrollbars
                style={{ height: gstReportList?.pagination?.total_records > 10 ? "calc(100vh - 192px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar"
            >
                <Table id="GSTreport">
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Month</th>
                            <th>Category</th>
                            <th>Ship from State(Emotorad)</th>
                            <th>Payment Source</th>
                            <th>Customer Name</th>
                            <th>Customer GSTN No</th>
                            <th>Dispatch WH</th>
                            <th>Region</th>
                            <th>Regional Manager</th>
                            <th>Bill To</th>
                            <th>Bill To State </th>
                            <th>Bill City</th>
                            <th>Billl to PIN Code</th>
                            <th>Ship to State</th>
                            <th>Ship to City</th>
                            <th>Ship to Pin</th>
                            <th>Order Id</th>
                            <th>Order Date</th>
                            <th>Invoice No</th>
                            <th>Invoice Date</th>
                            <th>Invoice Stage</th>
                            <th>Model</th>
                            <th>Type</th>
                            <th>Product Code</th>
                            <th>HSN/SAC</th>
                            <th>Qty</th>
                            <th>Rate</th>
                            <th>Value</th>
                            <th>Transport Charges</th>
                            <th>Taxable Value </th>
                            <th>IGST %</th>
                            <th>IGST Amt.</th>
                            <th>CGST %</th>
                            <th>CGST Amt.</th>
                            <th>SGST %</th>
                            <th>SGST Amt.</th>
                            <th>Total GST</th>
                            <th>Total Invoice Value</th>
                            <th>E Invoice</th>
                            <th>E Invoice Date</th>
                            <th>Ecomm. Type</th>
                            <th>Ecomm. OrderID</th>
                            <th>Franchise Code</th>
                            <th>Warehouse Code</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gstReportList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, gstReportList?.pagination?.current_page, gstReportList?.pagination?.record_limit)}    
                                </td>
                                <td>{CommonDateTime(elm?.invoice_at, "MMMM Do", "show")}</td>
                                <td>{(elm.order_source === "AMAZON_IN_API") ? "Amazon" : (elm.order_source === "FLIPKART_DELHI" || elm.order_source === "FLIPKART_BANGALORE" || elm.order_source === "FLIPKART_PUNE") ? "Flipkart" : elm.order_source}</td>
                                <td>{elm?.wh_state}</td>
                                <td>Bank</td>
                                <td>{elm.customer_name}</td>
                                <td>{elm?.gstin_no === "" ? "-" : elm?.gstin_no}</td>
                                <td>{elm?.dispatch_name}</td>
                                <td>{elm?.region}</td>
                                <td>{elm?.rm_manager === null ? "-" : elm?.rm_manager}</td>
                                <td>{elm?.customer_name}</td>
                                <td>{elm?.bill_state}</td>
                                <td>{elm?.bill_district}</td>
                                <td>{elm?.bill_pin_code}</td>
                                <td>{elm?.ship_state}</td>
                                <td>{elm?.ship_district}</td>
                                <td>{elm?.ship_pin_code}</td>
                                <td>{elm?.order_code}</td>
                                <td>{CommonDateTime(elm?.order_at, "LL", "show")}</td>
                                <td>{elm?.invoice_code}</td>
                                <td>{CommonDateTime(elm?.invoice_at, "LL", "show")}</td>
                                <td>{CommonStatus(elm?.invoice_status)}</td>
                                <td>{elm?.model}</td>
                                <td>{elm?.product_name}</td>
                                <td>{elm?.product_code}</td>
                                <td>{elm?.hsn_code}</td>
                                <td>{elm?.qty}</td>
                                <td>{AmountNumberFormat(elm?.product_price)}</td>
                                <td>
                                    {elm?.ntr === "Intra" ? (<>
                                        {AmountNumberFormat((parseFloat(elm?.sub_total) - (parseFloat(elm?.sub_sgst) + parseFloat(elm?.sub_cgst))) / elm?.qty)}
                                    </>) : (<>
                                        {AmountNumberFormat((parseFloat(elm?.sub_total) - parseFloat(elm?.sub_gst)) / elm?.qty)}
                                    </>)}
                                </td>
                                <td>-</td>
                                <td>
                                    {elm?.ntr === "Intra" ? (<>
                                        {AmountNumberFormat((parseFloat(elm?.sub_total) - (parseFloat(elm?.sub_sgst) + parseFloat(elm?.sub_cgst))))}
                                    </>) : (<>
                                        {AmountNumberFormat((parseFloat(elm?.sub_total) - parseFloat(elm?.sub_gst)))}
                                    </>)}
                                </td>
                                <td>
                                    {elm?.ntr === "Inter" ? (<>
                                        {AmountNumberFormat(parseFloat(elm?.gst_rate))}
                                    </>) : (<>
                                        0
                                    </>)}
                                </td>
                                <td>
                                    {elm?.ntr === "Inter" ? (<>
                                        {AmountNumberFormat(parseFloat(elm?.sub_gst))}
                                    </>) : (<>
                                        0
                                    </>)}
                                </td>
                                <td>
                                    {elm?.ntr === "Intra" ? (<>
                                        {AmountNumberFormat(parseFloat(elm?.cgst_rate))}
                                    </>) : (<>
                                        0
                                    </>)}
                                </td>
                                <td>
                                    {elm?.ntr === "Intra" ? (<>
                                        {AmountNumberFormat(parseFloat(elm?.sub_cgst))}
                                    </>) : (<>
                                        0
                                    </>)}
                                </td>
                                <td>
                                    {elm?.ntr === "Intra" ? (<>
                                        {AmountNumberFormat(parseFloat(elm?.sgst_rate))}
                                    </>) : (<>
                                        0
                                    </>)}
                                </td>
                                <td>
                                    {elm?.ntr === "Intra" ? (<>
                                        {AmountNumberFormat(parseFloat(elm?.sub_sgst))}
                                    </>) : (<>
                                        0
                                    </>)}
                                </td>
                                <td>{elm?.total_gst}</td>
                                <td>{AmountNumberFormat(elm?.sub_total)}</td>
                                <td>{elm?.ack_no === null ? "-" : elm?.ack_no}</td>
                                <td>{elm?.ack_date === null ? "-" : elm?.ack_date}</td>
                                <td>{elm?.ecom_type}</td>
                                <td>{elm?.ecom_id}</td>
                                <td>{elm?.franchise_code === null ? "-" : elm?.franchise_code}</td>
                                <td>{elm?.warehouse_code}</td>
                            </tr>)
                        })}
                        {gstReportList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Gst Data Report list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {gstReportList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={gstReportList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {(commonMainLoader?.url === "gst_list" && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default GstDataReport;