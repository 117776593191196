/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import CommonHeader from "./common/CommonHeader";
import { Scrollbars } from 'react-custom-scrollbars-2';
import DashBoardTables from "./common/DashBoardTables";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import CommonDateTime from "../Common/CommonDateTime";
import IncreDeacrementPercentage from "./common/IncreDeacrementPercentage";

const WarrantyRegistrationReport = () => {
    const { device_id, DashboardWarrantyRegistarionReport, DashboardWarrantyAgeGroupCategoryUseCaseReport, DashboardWarrantyStateVsResistrationsReport, DashboardWarrantyProductVsResistrationsReport } = useSelector((state) => state.adminReducers);
    const [ userLimit, setUserLimit ] = useState(5);
    
    
    // Warranty Registration Report Filter States
    const [ franchiseSelect, setFranchiseSelect ] = useState("");
    const [ stateSelect, setStateSelect ] = useState("");
    const [ regionSelect, setRegionSelect ] = useState("");
    const [ dateRange, setDateRange ] = useState([null, null]);
    const [ startDate, endDate ] = dateRange;
    

    // State Vs Registration Report Filter States
    const [ svrregionSelect, setsvrRegionSelect ] = useState("");
    const [ svrProductselect, setsvrProductselect ] = useState("");
    const [ svrdateRange, setsvrDateRange ] = useState([null, null]);
    const [ startDatesvr, endDateSvr ] = svrdateRange;
    const [ currentPage, setCurrentPage ] = useState(1);

    // Product Vs Registration Report Filter States
    const [ pvrregionSelect, setPvrRegionSelect ] = useState("");
    const [ pvrStateselect, setPvrStateselect ] = useState("");
    const [ pvrdateRange, setpvrDateRange ] = useState([null, null]);
    const [ startDatepvr, endDatePvr ] = pvrdateRange;
    const [ pvrcurrentPage, setPvrCurrentPage ] = useState(1);

    const { websocket } = useContext(WebSocketContext);

    // LOCAL DUMMY API CALLING RESPONSE STORE STATES
    const [ ProductVsRegistrations, setProductVsRegistrations ] = useState({});
    const [ RegionVsRegistrations, setRegionVsRegistrations ] = useState({});
    const [ DealerWiseAvailableInventory, setDealerWiseAvailableInventory ] = useState({});
    const [ CategoryWiseClassification, setCategoryWiseClassification ] = useState({});

    // WARRANTY REGISTRATIONS COUNT API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("warranty_register_report", {
            "from_date": startDate, 
            "to_date": endDate, 
            "franchise_id": franchiseSelect?.value, 
            "state_id": stateSelect?.value, 
            "region_id": regionSelect?.value
        });
    }, [ websocket, endDate, franchiseSelect, stateSelect, regionSelect ]);

    // STATE VS WARRANTY COUNT API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("state_vs_warranty_register", {
            "from_date": startDatesvr, 
            "to_date": endDateSvr, 
            "product_id": svrProductselect?.value, 
            "region_id": svrregionSelect?.value,
            "page_no": currentPage,
            "limit": userLimit
        });
    }, [ websocket, endDateSvr, svrProductselect, svrregionSelect, userLimit, currentPage ]);

    // PRODUCT VS WARRANTY COUNT API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("product_vs_warranty_register", {
            "from_date": startDatepvr, 
            "to_date": endDatePvr, 
            "state_id": pvrStateselect?.value, 
            "region_id": pvrregionSelect?.value,
            "page_no": pvrcurrentPage,
            "limit": userLimit
        });
    }, [ websocket, endDatePvr, pvrStateselect, pvrregionSelect, userLimit, pvrcurrentPage ]);

    // CLASSIFICATIONS WISE API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("classification_wise_reports", {});
    }, [ websocket ]);

    // COMMON API CALLING
    const DashBoardWebSocketsAPIcalling = (api_url, request_params) => {
        let param;

        if(Object?.keys(request_params)?.length > 0) {
            const { from_date, to_date, franchise_id, state_id, region_id, product_id, page_no, limit } = request_params;

            param = {
                "transmit": "broadcast",
                "url": api_url,
                "request": {},
                "DeviceId": device_id
            };

            // FROM AND TO DATE FILTER
            if((from_date !== null && from_date !== undefined) && (to_date !== null && to_date !== undefined)) {
                param.request.from_date = CommonDateTime(from_date, "YYYY-MM-DD", "hide");
                param.request.to_date = CommonDateTime(to_date, "YYYY-MM-DD", "hide");
            };

            // FRANCHISE ID FILTER
            if(franchise_id) {
                param.request.franchise_id = franchise_id;
            };

            // STATE ID FILTER
            if(state_id) {
                param.request.state_id = state_id;
            };

            // PRODUCT ID FILTER
            if(product_id) {
                param.request.product_id = product_id;
            };

            // REGION ID FILTER
            if(region_id) {
                param.request.region_id = region_id;
            };

            // PAGE NO FILTER
            if(page_no) {
                param.request.page_no = page_no;
            };

            // LIMIT FILTER
            if(limit) {
                param.request.limit = limit;
            };
        } else {
            param = {
                "transmit": "broadcast",
                "url": api_url,
                "request": {},
                "DeviceId": device_id
            };
        };
        wsSend_request(websocket, param);
    };

    const API_BASE_URL = "https://dummyjson.com/c";

    // PRODUCT VS REGISTRATIONS API CALLING
    useEffect(() => {
        DashBoardAPIcalling(`${API_BASE_URL}/c76a-101c-49e7-bc53`, setProductVsRegistrations);
    }, []);

    // REGION VS REGISTRATIONS API CALLING
    useEffect(() => {
        DashBoardAPIcalling(`${API_BASE_URL}/c954-126b-4284-819c`, setRegionVsRegistrations);
    }, []);

    // DEALER-WISE AVAILABLE INventory
    useEffect(() => {
        DashBoardAPIcalling(`${API_BASE_URL}/653a-305e-4acb-856c`, setDealerWiseAvailableInventory);
    }, []);

    // CATEGORY WISE CLASIFICATIONS API CALLING
    useEffect(() => {
        DashBoardAPIcalling(`${API_BASE_URL}/79c5-f12f-4ce5-a922`, setCategoryWiseClassification);
    }, []);

    // COMMON API CALLING
    const DashBoardAPIcalling = (api_url, storeStateFunction) => {
        fetch(api_url).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // parses JSON response into native JavaScript objects
        })
        .then(data => {
            storeStateFunction(data);
        })
        .catch(error => {
            console.error('Fetch Error:', error);
        });
    };

    // State vs Registrations TABLE COLUMNS ARRAY
    const StateVsRegistrationsTableColumns = [
        { key: 'state_name', label: 'State', type: "label", sort: "" },
        { key: 'register_count', label: 'Registrations', type: "label", sort: "" },
        { key: 'mismatch_count', label: 'Mismatched Frames', type: "label", sort: "" },
        // { key: 'Difference_percantage', label: '% Comparison', type: "label_only_percentage", sort: "" },
    ];

    // Product vs Registrations TABLE COLUMNS ARRAY
    const ProductVsRegistrationsTableColumns = [
        { key: 'product_name', label: 'Product Name', type: "label", sort: "" },
        { key: 'register_count', label: 'Registrations', type: "label", sort: "" },
        { key: 'mismatch_count', label: 'Mismatched Frames', type: "label", sort: "" },
        // { key: 'Difference_percantage', label: '% Comparison', type: "label_only_percentage", sort: "" },
    ];

    // Region vs Registrations TABLE COLUMNS ARRAY
    const RegionVsRegistrationsTableColumns = [
        { key: 'region_name', label: 'Region Name', type: "label", sort: "" },
        { key: 'registrations_count', label: 'Registrations', type: "label", sort: "" },
        { key: 'primary_sale_count', label: 'Mismatched Frames', type: "label", sort: "" },
        { key: 'Difference_percantage', label: '% Comparison', type: "label_only_percentage", sort: "" },
    ];

    // Dealer-Wise Available Inventory TABLE COLUMNS ARRAY
    const DealerWiseAvailableInventoryTableColumns = [
        { key: 'unique_dealer', label: 'Unique Dealer', type: "label", sort: "" },
        { key: 'primary_sale', label: 'Primary Sale', type: "label", sort: "" },
        { key: 'secondary_sale', label: 'Secondary Sale', type: "label", sort: "" },
        { key: 'available_qty', label: 'Available Qty', type: "label", sort: "" },
    ];

    // AGE GROUPS CLASSIFICATIONS TABLE COLUMNS ARRAY
    const AgeGroupClassificationsTableColumns = [
        { key: 'age_group', label: 'Age', type: "label", sort: "" },
        { key: 'count', label: 'Record Count', type: "label", sort: "" },
    ];

    // CATEGORY WISE CLASSIFICATIONS TABLE COLUMNS ARRAY
    const CategoryWiseClassificationsTableColumns = [
        { key: 'category_name', label: 'Category', type: "label", sort: "" },
        { key: 'record_count', label: 'Record Count', type: "label_k", sort: "" },
    ];

    // USE CASE WISE CLASSIFICATIONS TABLE COLUMNS ARRAY
    const UseCaseWiseClassificationsTableColumns = [
        { key: 'use_case', label: 'Use Case', type: "label", sort: "" },
        { key: 'count', label: 'Record Count', type: "label", sort: "" },
    ];

    // WARRANTY REGISTRATIONS REPORT COLUMNS
    const WarrantyRegistrationColumns = ({colmd, header, registrations_count, primary_count, difference_count}) => {
        return(<div className={`col-md-${colmd}`}>
            <div className="warranty_registartions_columns">
                <h4 className="Monthly_heading Monthly">{header}</h4>
                <div className="warranty_reg_cols">
                    <p>Registrations</p>
                    <h4>{registrations_count}</h4>
                </div>
                <div className="warranty_reg_cols">
                    <p>Mismatched Frames</p>
                    <h4>{primary_count}</h4>
                </div>
                <div className="warranty_reg_cols">
                    <p>% Comparison</p>
                    <h4>
                        {IncreDeacrementPercentage(parseFloat(parseFloat(difference_count).toFixed(2)), "No_Badge")}
                    </h4>
                </div>
            </div>
        </div>)
    };


    return(<div className="Main_section dashboard_Main_section" style={{ padding: "0px", backgroundColor: "transparent" }}>
        <div className="dashboard_wrapper">
            <Scrollbars
                style={{
                    height: "calc(100vh - 79px)",
                }}
                renderView={props => <div {...props} className="view" />}
                className="ScrollbarsSidebar"
            >
                {/* Warranty Registration Report : Table */}
                <div className="dashboard_main_wrapper" >
                    <CommonHeader 
                        heading={"Warranty Registration Report"} 
                        isSearchAllow={false}
                        searchType={"Franchise Code, ERP ID, Customer Name"}
                        isDatePickerAllow={true}
                        dateRange={dateRange} 
                        setDateRange={setDateRange}
                        isSkuTypeAllow={false}
                        isOrderStatusAllow={false}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={true}
                        Dealer={franchiseSelect} 
                        setDealer={setFranchiseSelect}
                        isStateAllow={true}
                        stateSelect={stateSelect}
                        setStateSelect={setStateSelect}
                        isRegionAllow={true}
                        regionSelect={regionSelect}
                        setRegionSelect={setRegionSelect}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper" style={{ margin: "10px 0 0" }}>
                        <div className="row">
                            <WarrantyRegistrationColumns
                                colmd={"3"} 
                                header={"Today"} 
                                registrations_count={DashboardWarrantyRegistarionReport?.today_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.today_count}
                                primary_count={DashboardWarrantyRegistarionReport?.today_mismatch_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.today_mismatch_count} 
                                difference_count={DashboardWarrantyRegistarionReport?.today_vs_yesterday_diff === undefined ? 0 : DashboardWarrantyRegistarionReport?.today_vs_yesterday_diff}
                            />
                            <WarrantyRegistrationColumns
                                colmd={"3"} 
                                header={"Monthly"} 
                                registrations_count={DashboardWarrantyRegistarionReport?.month_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.month_count}
                                primary_count={DashboardWarrantyRegistarionReport?.month_mismatch_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.month_mismatch_count} 
                                difference_count={DashboardWarrantyRegistarionReport?.month_vs_last_month_diff === undefined ? 0 : DashboardWarrantyRegistarionReport?.month_vs_last_month_diff}
                            />
                            <WarrantyRegistrationColumns
                                colmd={"3"} 
                                header={"Yearly"} 
                                registrations_count={DashboardWarrantyRegistarionReport?.year_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.year_count}
                                primary_count={DashboardWarrantyRegistarionReport?.year_mismatch_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.year_mismatch_count} 
                                difference_count={DashboardWarrantyRegistarionReport?.year_vs_last_year_diff === undefined ? 0 : DashboardWarrantyRegistarionReport?.year_vs_last_year_diff}
                            />
                        </div>
                    </div>
                </div>
                
                {/* State Vs Registrations : Table */}
                <div className="dashboard_main_wrapper" >
                    <CommonHeader 
                        heading={"State Vs Registrations"} 
                        isSearchAllow={false}
                        searchType={"Franchise Code, ERP ID, Customer Name"}
                        isDatePickerAllow={true}
                        dateRange={svrdateRange} 
                        setDateRange={setsvrDateRange}
                        isSkuTypeAllow={false}
                        isOrderStatusAllow={false}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={true}
                        regionSelect={svrregionSelect}
                        setRegionSelect={setsvrRegionSelect}
                        isProductAllow={true}
                        Productselect={svrProductselect}
                        setProductselect={setsvrProductselect}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ margin: "10px 0 0" }}>
                        <h4 className="Monthly_heading Monthly">State-Wise Report</h4>
                        <DashBoardTables 
                            isSrNoAllow={false}
                            Columns={StateVsRegistrationsTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={DashboardWarrantyStateVsResistrationsReport} // ORDER LIST
                            PaginationCurrentPage={currentPage}
                            setPaginationCurrentPage={setCurrentPage}
                            PaginationSet={DashboardWarrantyStateVsResistrationsReport?.pagination} // CURRENT TAB PAGINATION
                            isGrandTotalAllow={{
                                allow: DashboardWarrantyStateVsResistrationsReport?.list?.length > 0,
                                table_name: "State-Vs-Registrations",
                                title: "Grand Total",
                                colspan: null,
                                data: DashboardWarrantyStateVsResistrationsReport
                            }}
                            height={"570px"}
                            id={""}
                        />
                    </div>
                </div>

                {/* Product Vs Registrations : Table */}
                <div className="dashboard_main_wrapper" >
                    <CommonHeader 
                        heading={"Product Vs Registrations"} 
                        isSearchAllow={false}
                        searchType={""}
                        isDatePickerAllow={true}
                        dateRange={pvrdateRange} 
                        setDateRange={setpvrDateRange}
                        isSkuTypeAllow={false}
                        isOrderStatusAllow={false}
                        setCurrentPage={setPvrCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={true}
                        stateSelect={pvrStateselect}
                        setStateSelect={setPvrStateselect}
                        isRegionAllow={true}
                        regionSelect={pvrregionSelect}
                        setRegionSelect={setPvrRegionSelect}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ margin: "10px 0 0" }}>
                        <h4 className="Monthly_heading Monthly">Product-Wise Report</h4>
                        <DashBoardTables 
                            isSrNoAllow={false}
                            Columns={ProductVsRegistrationsTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={DashboardWarrantyProductVsResistrationsReport} // ORDER LIST
                            PaginationCurrentPage={pvrcurrentPage}
                            setPaginationCurrentPage={setPvrCurrentPage}
                            PaginationSet={{
                                current_page: "1", 
                                record_limit: "3",
                            }} // CURRENT TAB PAGINATION
                            isGrandTotalAllow={{
                                allow: DashboardWarrantyProductVsResistrationsReport?.list?.length > 0,
                                table_name: "State-Vs-Registrations",
                                title: "Grand Total",
                                colspan: null,
                                data: DashboardWarrantyProductVsResistrationsReport,
                            }}
                            height={"570px"}
                            id={""}
                        />
                    </div>
                </div>

                {/* Region Vs Registrations : Table */}
                <div className="dashboard_main_wrapper">
                    <CommonHeader 
                        heading={"Region Vs Registrations"} 
                        isSearchAllow={false}
                        searchType={""}
                        isDatePickerAllow={true}
                        dateRange={dateRange} 
                        setDateRange={setDateRange}
                        isSkuTypeAllow={false}
                        isOrderStatusAllow={false}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ margin: "10px 0 0" }}>
                        <h4 className="Monthly_heading Monthly">Region-Wise Report</h4>
                        <DashBoardTables 
                            isSrNoAllow={false}
                            Columns={RegionVsRegistrationsTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={RegionVsRegistrations} // ORDER LIST
                            PaginationCurrentPage={""}
                            setPaginationCurrentPage={""}
                            PaginationSet={{
                                current_page: "1", 
                                record_limit: "3",
                            }} // CURRENT TAB PAGINATION
                            isGrandTotalAllow={{
                                allow: RegionVsRegistrations?.list?.length > 0,
                                table_name: "Region-Vs-Registrations",
                                title: "Grand Total",
                                colspan: null,
                                data: RegionVsRegistrations,
                            }}
                            height={"320px"}
                            id={""}
                        />
                    </div>
                </div>

                {/* Dealer-Wise Available Inventory : Table */}
                <div className="dashboard_main_wrapper">
                    <CommonHeader 
                        heading={"Dealer-Wise Available Inventory"} 
                        isSearchAllow={false}
                        searchType={""}
                        isDatePickerAllow={true}
                        dateRange={dateRange} 
                        setDateRange={setDateRange}
                        isSkuTypeAllow={false}
                        isOrderStatusAllow={false}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ margin: "10px 0 0" }}>
                        <h4 className="Monthly_heading Monthly">Dealer-Wise Available Inventory</h4>
                        <DashBoardTables 
                            isSrNoAllow={false}
                            Columns={DealerWiseAvailableInventoryTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={DealerWiseAvailableInventory} // ORDER LIST
                            PaginationCurrentPage={""}
                            setPaginationCurrentPage={""}
                            PaginationSet={{
                                current_page: "1", 
                                record_limit: "3",
                            }} // CURRENT TAB PAGINATION
                            isGrandTotalAllow={{
                                allow: DealerWiseAvailableInventory?.list?.length > 0,
                                table_name: "Dealer-Wise-Available-Inventory",
                                title: "Grand Total",
                                colspan: null,
                                data: DealerWiseAvailableInventory,
                            }}
                            height={"320px"}
                            id={""}
                        />
                    </div>
                </div>

                <div className="dashboard_main_wrapper" style={{ marginBottom: "0px" }}>
                    <div className="row">
                        <div className="col-md-6 dashboard-table-center" style={{ margin: "0 0 40px" }}>
                            <div className="DashboardMainHeader">
                                <h4>Age-Group Wise Classification</h4>
                            </div>
                            <h4 className="Monthly_heading Monthly">Age Group-Wise Classification</h4>
                            <DashBoardTables 
                                isSrNoAllow={false}
                                Columns={AgeGroupClassificationsTableColumns} // TABLE COLLUMNS & ROWS
                                OrderList={{
                                    list: DashboardWarrantyAgeGroupCategoryUseCaseReport?.age_group_counts
                                }} // ORDER LIST
                                PaginationCurrentPage={""}
                                setPaginationCurrentPage={""}
                                PaginationSet={{
                                    current_page: "1", 
                                    record_limit: "3",
                                }} // CURRENT TAB PAGINATION
                                isGrandTotalAllow={{
                                    allow: DashboardWarrantyAgeGroupCategoryUseCaseReport?.age_group_counts?.length > 0,
                                    table_name: "Use-Case-Wise-Classification",
                                    title: "Grand Total",
                                    colspan: null,
                                    data: {
                                        list:  DashboardWarrantyAgeGroupCategoryUseCaseReport?.age_group_counts
                                    },
                                }}
                                height={"340px"}
                                id={""}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center" style={{ margin: "0 0 40px" }}>
                            <div className="DashboardMainHeader">
                                <h4>Category-Group Wise Classification</h4>
                            </div>
                            <h4 className="Monthly_heading Monthly">Category-Wise Classification</h4>
                            <DashBoardTables 
                                isSrNoAllow={false}
                                Columns={CategoryWiseClassificationsTableColumns} // TABLE COLLUMNS & ROWS
                                OrderList={CategoryWiseClassification} // ORDER LIST
                                PaginationCurrentPage={""}
                                setPaginationCurrentPage={""}
                                PaginationSet={{
                                    current_page: "1", 
                                    record_limit: "3",
                                }} // CURRENT TAB PAGINATION
                                isGrandTotalAllow={{
                                    allow: CategoryWiseClassification?.list?.length > 0,
                                    table_name: "Age-Group-Category-Use-Case-Wise-Classification",
                                    title: "Grand Total",
                                    colspan: null,
                                    data: CategoryWiseClassification?.grand_total,
                                }}
                                height={"283px"}
                                id={""}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center">
                            <div className="DashboardMainHeader">
                                <h4>Use Case-Wise Classification</h4>
                            </div>
                            <h4 className="Monthly_heading Monthly">Use Case-Wise Classification</h4>
                            <DashBoardTables 
                                isSrNoAllow={false}
                                Columns={UseCaseWiseClassificationsTableColumns} // TABLE COLLUMNS & ROWS
                                OrderList={{
                                    list: DashboardWarrantyAgeGroupCategoryUseCaseReport?.use_case_counts
                                }} // ORDER LIST
                                PaginationCurrentPage={""}
                                setPaginationCurrentPage={""}
                                PaginationSet={{
                                    current_page: "1", 
                                    record_limit: "3",
                                }} // CURRENT TAB PAGINATION
                                isGrandTotalAllow={{
                                    allow: DashboardWarrantyAgeGroupCategoryUseCaseReport?.use_case_counts?.length > 0,
                                    table_name: "Use-Case-Wise-Classification",
                                    title: "Grand Total",
                                    colspan: null,
                                    data: {
                                        list:  DashboardWarrantyAgeGroupCategoryUseCaseReport?.use_case_counts
                                    },
                                }}
                                height={"420px"}
                                id={""}
                            />
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>
    </div>)
};

export default WarrantyRegistrationReport;