import React from "react";
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "./DataTableFilter";
import Select from 'react-select';
import { useSelector } from "react-redux";
import CommonExport from "./CommonExport";

const MainOrderTableFilterWithoutRedux = (props) => {
    const { isDateAllow, filterType, userSearch, setUserSearch, userType, setUserType, userLimit, setUserLimit, currentPage, setCurrentPage, setOrderSource, orderSource, searchType, isRegionAllow, selectRegion, setSelectRegion, isWarehouseAllow, selectWarehouse, setSelectWarehouse, FromDate, setFromDate, ToDate, setToDate, startDate, setStartDate, endDate, setEndDate, isExport } = props;
    const { Regionlistall, wareHouselistall } = useSelector((state) => state.adminReducers);
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} - (${item?.warehouse_code})- (${item?.display_name})` }));
    const today = new Date();

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // SELECT USER TYPE
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // SELECT REGION
    const selectRegionFunction = (e) => {
        setSelectRegion(e.target.value);
        setCurrentPage(1);
    };

    // SELECT START DATE
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // SELECT END DATE
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // WAREHOUSE SELECT
    const WareHouseSelect = (value) => {
        if(value !== null) {
            setSelectWarehouse(value);
        } else {
            setSelectWarehouse("");
        };
    };

    // EXPORT FUNCTION
    const ExportFunction = (url) => {
        if(url === "order_list") {
            CommonExport("order_list", { "FromDate": FromDate, "ToDate": ToDate, "status": "REJECTED", "region_id": selectRegion, "search": userSearch.trim(), "order_type": orderSource });
        }
    };

    return(<div className="card-header-Order-Filter-New" style={{ width: "100%" }}>
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    {/* FROM DATE AND TO DATE FILTERS */}
                    {isDateAllow && (<React.Fragment>
                        <div className="col-md-3" style={{ width: "16%", paddingRight: "0px" }}>
                            <Form.Group>
                                <div className="datepickField min">
                                    <DatePicker
                                        selected={FromDate}
                                        startDate={FromDate}
                                        endDate={ToDate}
                                        onChange={(date) => DateStartGet(date)}
                                        dateFormat="dd/MM/yyyy"
                                        maxDate={today}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter From Date"
                                        isClearable={FromDate !== "" ? true : false}
                                        name="statDate"
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3" style={{ width: "16%", paddingRight: "0px" }} >
                            <Form.Group>
                                <div className="datepickField">
                                    <DatePicker
                                        selected={ToDate}
                                        startDate={FromDate}
                                        endDate={ToDate}
                                        minDate={startDate}
                                        maxDate={endDate}
                                        onChange={(date) => DateEndGet(date)}
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter To Date"
                                        isClearable={ToDate !== "" ? true : false}
                                        name="ToDate"
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                    </React.Fragment>)}

                    {/* WAREHOUSE FILTER */}
                    {isWarehouseAllow && (<div className="col-md-3" style={{ width: "19%", paddingRight: "0px" }}>
                        <Form.Group>
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={selectWarehouse}
                                    onChange={(e) => WareHouseSelect(e)}
                                    isClearable
                                    options={optionsWarehouse}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Warehouse"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>)}

                    {/* SELECT REGION */}
                    {isRegionAllow && (<div className="col-md-3" style={{ width: "13%",paddingRight: "0px" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={selectRegion} onChange={(e) => selectRegionFunction(e)}>
                            <option value="">Select Region</option>
                            {Regionlistall?.filter((elz) => {
                                return elz?.is_active === true
                            })?.map((elm, index) => {
                                return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                            })}
                        </select>
                    </div>)}

                    {/* EXPORT BUTTON */}
                    {isExport?.isExportAllow && (<div className="col-md-3" style={{ width: "auto" }}>
                        <button 
                            className="btn exportbtn" 
                            disabled={isExport?.isDisabled} 
                            style={{ width: "auto" }} 
                            onClick={() => ExportFunction(isExport.url)}
                        >
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>)}
                </div>
            </div>
        </div>
        <DataTableFilter
            filterType={filterType}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            callSelectSource={setOrderSource}
            orderSource={orderSource}
            searchType={searchType}
        />
    </div>)
}

export default MainOrderTableFilterWithoutRedux;