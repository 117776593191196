/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../../Common/DataTableFilter";
import { useNavigate } from "react-router-dom";
import { CREATE_DISPATCH_LIST_URL } from "../../Shared/constant";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import CommonTable from "../../Common/CommonTable";

const DispatchList = (props) => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { DispatchLists, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "district_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all state list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "state_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get region list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "region_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "dispatch_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };
    
    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_DISPATCH_LIST_URL);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'dispatch_name', label: 'Dispatch Name', type: "label", sort: "", isCollapsable: false },
        { key: 'email', label: 'Email', type: "label", sort: "", isCollapsable: false },
        { key: 'mobile', label: 'Mobile', type: "label", sort: "", isCollapsable: false },
        { key: 'pin_code_id', label: 'PIN code', type: "label_get_from_list", filterFrom: { list: "pin_code", label: "pin_code" }, sort: "", isCollapsable: false },
        { key: 'warehouse_id', label: 'Warehouse', type: "label_get_from_list", filterFrom: { list: "warehouses", label: "warehouse_name" }, sort: "", isCollapsable: false },
        { key: 'is_active', label: 'Status', type: "status_active", sort: "", isCollapsable: false },
        { key: 'gst_no', label: 'GST Number', type: "label", sort: "", isCollapsable: true },
        { key: 'pin_code_id', label: 'City', type: "city_label_get_from_warehouse", filterFrom: { list: "pin_code", label: "pin_code" }, sort: "", isCollapsable: true },
        { key: 'pin_code_id', label: 'State', type: "state_label_get_from_warehouse", filterFrom: { list: "pin_code", label: "pin_code" }, sort: "", isCollapsable: true },
        { key: 'address', label: 'Address', type: "label", sort: "", isCollapsable: true },
        { key: 'created_at', label: 'Created At', type: "date", sort: "", isCollapsable: true },
    ];

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Dispatch"}
            searchType={"Dispatch Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[2]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Dispatch",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(DispatchLists?.pagination?.total_records) === DispatchLists?.list?.length ? "calc(100vh - 140px)" : parseInt(DispatchLists?.pagination?.total_records) > 10 ? "calc(100vh - 175px)" : "calc(100vh - 140px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={true}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={DispatchLists} // CATEGORY LIST
            Pagination={DispatchLists?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[2]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: true,
                label: "edit",
                naviagte: `/edit-dispatch/`
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
        />
    </section>)
}

export default DispatchList;