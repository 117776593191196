/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import SidebarMain from "./SidebarMain";
import SidebarChild from "./SidebarChild";
import { Link } from "react-router-dom";
import { DASHBOARD_URL } from "../constant";
import { useDispatch } from "react-redux";
import { setGetMagicCode } from "../../../redux/actions/adminActions";
import { Image } from "react-bootstrap";
import LogoImage from "../../../assets/img/BK/logo.svg";

const Sidebar = (props) => {
    const { setDivWidth, sidebarToggle } = props
    const myDivRef = useRef(null);
    const [menuStates, setMenuStates] = useState({
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
    });
    const dispatch = useDispatch();

    useEffect(() => {
        const updateWidth = () => {
          if (myDivRef.current) {
            setDivWidth(myDivRef.current.clientWidth);
          }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => {
          window.removeEventListener('resize', updateWidth);
        };
    }, []); 

    const callBackErrorHandle = (e) =>{
        if(window.location.origin !== "http://localhost:3000" && window.location.origin !== "http://localhost:3001"){
            dispatch(setGetMagicCode(""));
        }
    };

    return(<div className={!sidebarToggle ? "sidebarWrapper close" : "sidebarWrapper"} ref={myDivRef}>
        <div className="navLogo">
            <Link to={DASHBOARD_URL} className="nav-logo">
              <Image src={LogoImage} alt="logo" onErrorCapture={callBackErrorHandle} onError={callBackErrorHandle} />
            </Link>
        </div>
        <SidebarMain menuStates={menuStates} setMenuStates={setMenuStates}/>
        <SidebarChild menuStates={menuStates} setMenuStates={setMenuStates}/>
    </div>)
};

export default Sidebar;