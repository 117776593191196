/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonewModel from "../../Common/CommonewModel";
import CommonTable from "../../Common/CommonTable";

const Box = (props) => {
    const { boxList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[3]?.Edit || PermissionsAccess?.Masters[3]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "box_list",
                "request": {
                    "status": userType === "active" ? true : userType === "inactive" ? false : "",
                    "limit": userLimit,
                    "page_no": currentPage,
                    "search": userSearch.trim()
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [websocket, userSearch, userType, currentPage, userLimit]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url: "",
            action: false
        }));
    }, []);

    const AddNewBoxModal = () => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Create New Box",
            subtitle: "Create New Box",
            modalType: "form",
            button: "Create"
        })
    };

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'box_name', label: 'Box Name', type: "label", sort: "", isCollapsable: false },
        { key: 'box_height', label: 'Box Height', type: "label", sort: "", isCollapsable: false },
        { key: 'box_width', label: 'Box Width', type: "label", sort: "", isCollapsable: false },
        { key: 'box_length', label: 'Box Length', type: "label", sort: "", isCollapsable: false },
        { key: 'is_active', label: 'Status', type: "status_active", sort: "", isCollapsable: false },
    ];

    return (<section className="Main_section">
        <DataTableFilter
            filterType={""}
            searchType={"Box Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[3]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Box",
                function: AddNewBoxModal
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{ height: boxList?.pagination?.total_records > 10 ? "calc(100vh - 175px)" : "calc(100vh - 139px)"}} 
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={boxList} // BOX LIST
            Pagination={boxList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[3]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[3]?.Edit,
                label: "edit_box_modal",
                naviagte: ``
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
        />
        {showModalNew?.open && (<CommonewModel setCurrentPage={setCurrentPage} setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
    </section>)
}

export default Box;