

/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonTable from "../../Common/CommonTable";
import PaymentDateTableFilter from "../../Common/PaymentDateTableFilter";

const AccountLedgerReport = (props) => {
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ FromDate, setFromDate ] = useState("");
    const [ ToDate, setToDate ] = useState("");
    const { device_id, DNCNLedgerList } = useSelector((state) => state.adminReducers);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // get order_list all
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "dncn_ledger",
            "request": {
                "limit": userLimit,
                "page_no": currentPage,
                "from_date": null,
                "to_date": null,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    }, [websocket, currentPage, userLimit, FromDate, ToDate]);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'franchise_id', label: 'Franchise Code', type: "label_get_from_list_summary_details", filterFrom: { list: "franchise", label: "franchise_code" }, sort: "", isCollapsable: false },
        { key: 'franchise_id', label: 'Name', type: "label_get_from_list", filterFrom: { list: "franchise", label: "customer_name" }, sort: "", isCollapsable: false },
        { key: '', label: 'Opening Balance', type: "label_opening_balance", sort: "", isCollapsable: false },
        { key: 'total_credit', label: 'Credit Amount', type: "label_price", sort: "", isCollapsable: false },
        { key: 'total_debit', label: 'Debit Amount', type: "label_price", sort: "", isCollapsable: false },
        { key: 'remarks', label: 'Closing Balance', type: "label_closing_balance", sort: "", isCollapsable: false },
    ];

    return (<section className="Main_section">
        <PaymentDateTableFilter 
            searchAllow={false}
            FromDate={FromDate}
            setFromDate={setFromDate}
            ToDate={ToDate}
            setToDate={setToDate}
            setCurrentPage={setCurrentPage}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            filterType={"Account-Ledger-Report"}
            franchiseFilter={false}
            exportBtn={{
                isAllow: true,
                api_url: "dncn_ledger"
            }}
            exportSmallBtn={{
                isAllow: false,
                api_url: ""
            }}
            FromDateStartDateFlag={true}
            ButtonSet={{
                buttontitle: "",
                type: "",
                navigate_url: "",
            }}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{ height: DNCNLedgerList?.pagination?.total_records > 10 ? "calc(100vh - 177px)" : "calc(100vh - 139px)"}} 
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={DNCNLedgerList} // ACCOUNT LEDGER REPORT LIST
            Pagination={DNCNLedgerList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={false} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: false,
                label: "edit",
                naviagte: ``
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isTotalAndGrand={{
                isShow: false,
                filterType: "",
                colspan: ""
            }}
        />
    </section>)
}

export default AccountLedgerReport;

